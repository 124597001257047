// @flow
import * as React from "react";
import * as Sentry from "@sentry/browser";

type Props = {
  children: React.Node
};

type State = {
  hasError: boolean
};

// Error boundary that removes it's children from the DOM if there is an error
class HiddenErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false
  };

  componentDidCatch(error, errorInfo) {
    this.setState(() => ({
      hasError: true
    }));

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;

    // don't display anything if there is an error
    if (hasError) {
      return null;
    }

    const { children } = this.props;
    return children;
  }
}
export default HiddenErrorBoundary;
