// @flow
import {
  createAction,
  WEB3_AUTHENTICATION,
  WEB3_TRANSACTION,
  WEB3_INITIALIZE
} from "./action-types";

import {
  LEXICONOMY_CONTRACT,
  SALE_CONTRACT
} from "../services/contracts/details";

import { lemmaToBytes } from "../services/utils";

export const web3Initialize = () => createAction(WEB3_INITIALIZE);

export const web3Authentication = {
  request: () => createAction(WEB3_AUTHENTICATION.REQUEST),
  success: ({ account, network }: Object) =>
    createAction(WEB3_AUTHENTICATION.SUCCESS, { account, network }),
  failure: ({ network }: Object = {}) =>
    createAction(WEB3_AUTHENTICATION.FAILURE, { network })
};

export const web3Transaction = {
  request: ({ contract, method, value, params }: Object) =>
    createAction(WEB3_TRANSACTION.REQUEST, {
      contract,
      method,
      value,
      params
    }),
  success: ({ method, account, params, value }: Object) =>
    createAction(WEB3_TRANSACTION.SUCCESS, {
      method,
      account,
      value,
      params
    }),
  confirmation: ({ method, message, receipt }: Object) =>
    createAction(WEB3_TRANSACTION.CONFIRMATION, {
      method,
      message: {
        kind: "success",
        text: message
      },
      receipt
    }),
  failure: ({ method, message }: Object) =>
    createAction(WEB3_TRANSACTION.FAILURE, {
      method,
      message: {
        kind: "error",
        text: message
      }
    })
};

export const makeLemmaBid = ({
  lemma,
  value
}: {
  lemma: string,
  value: number
}) => {
  const lemmaBytes = lemmaToBytes(lemma);
  return web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "makeLemmaBid",
    value,
    params: [lemmaBytes]
  });
};

export const defineLemma = ({ id, definition }: Object) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "defineLemma",
    params: [id, definition]
  });

export const composeLemma = ({ lemma, breakpoints, value }: Object) => {
  const lemmaBytes = lemmaToBytes(lemma);
  return web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "composeLemma",
    value,
    params: [lemmaBytes, breakpoints]
  });
};

export const createSaleAuction = ({
  id,
  startingPrice,
  endingPrice,
  duration
}: Object) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "createSaleAuction",
    params: [id, startingPrice, endingPrice, duration]
  });

export const bidOnSaleAuction = ({ id, value }: object) =>
  web3Transaction.request({
    contract: SALE_CONTRACT,
    method: "bid",
    params: [id],
    value
  });

export const cancelSaleAuction = ({ id }: Object) =>
  web3Transaction.request({
    contract: SALE_CONTRACT,
    method: "cancelAuction",
    params: [id]
  });

export const donate = ({ value }: { value: number }) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "donate",
    value
  });

export const setCompositionFee = ({
  id,
  value
}: {
  id: string,
  value: number
}) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "setCompositionFee",
    params: [id, value]
  });

export const withdrawFunds = () =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "withdrawFunds"
  });

export const makeOffer = ({ id, value }: { id: string, value: number }) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "makeOffer",
    value,
    params: [id]
  });

export const cancelOffer = ({ id }: { id: string }) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "cancelOffer",
    params: [id]
  });

export const acceptOffer = ({ id }: { id: string }) =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "acceptOffer",
    params: [id]
  });

export const releaseNextLemmas = () =>
  web3Transaction.request({
    contract: LEXICONOMY_CONTRACT,
    method: "releaseNextLemmas"
  });
