// @flow
import React, { Component, type Node } from "react";
import * as Sentry from "@sentry/browser";

import Button from "./Button";

import "./PageErrorBoundary.css";

type Props = {
  children: Node
};

type State = {
  hasError: boolean,
  errorInfo: string
};

class PageErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
    errorInfo: ""
  };

  componentDidCatch(error, errorInfo: Object) {
    this.setState(() => ({
      hasError: true,
      errorInfo
    }));

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;

    // don't display anything if there is an error
    if (hasError) {
      return (
        <div>
          <h1>
            <span role="img" aria-label="dead emoji">
              😵
            </span>
          </h1>
          <div>
            Something went wrong! Try refresh the page and verify your Meta Mask
            connection
          </div>
          <div className="error-feedback">
            <Button onClick={() => Sentry.showReportDialog()}>
              Report feedback
            </Button>
          </div>
        </div>
      );
    }

    const { children } = this.props;
    return children;
  }
}
export default PageErrorBoundary;
