// @flow
import React from "react";
import { connect } from "react-redux";

import MetaMaskModal from "./MetaMaskModal";
import BidModal from "./BidModal";
import SellModal from "./SellModal";
import BuyModal from "./BuyModal";
import DonateModal from "./DonateModal";
import ComposeModal from "./ComposeModal";
import ComfirmationModal from "./ComfirmationModal";
import DefineModal from "./DefineModal";
import InvalidNetworkModal from "./InvalidNetworkModal";
import CompositionFeeModal from "./CompositionFeeModal";
import MakeOfferModal from "./MakeOfferModal";

import { isWeb3Authenticated, getNetwork } from "../../selectors/selectors";

type Props = {
  name: string,
  data: Object,
  isAuthenticated: boolean,
  network: string
};

const modals = {
  BidModal,
  SellModal,
  MetaMaskModal,
  BuyModal,
  DonateModal,
  ComposeModal,
  ComfirmationModal,
  DefineModal,
  CompositionFeeModal,
  MakeOfferModal,
  InvalidNetworkModal
};

const web3RequiredModals = {
  BidModal,
  SellModal,
  BuyModal,
  DonateModal,
  ComposeModal,
  ComfirmationModal,
  DefineModal,
  CompositionFeeModal,
  MakeOfferModal
};

const ModalContainer = ({
  name,
  data = {},
  isAuthenticated = false,
  network
}: Props) => {
  // if there isn't a modal
  if (!name) return null;

  // modal to show
  const Modal = modals[name];

  // if there is no modal return nothing
  if (!Modal) return null;

  // verify user is authenticated if the modal requires it
  const web3Required = Boolean(web3RequiredModals[name]);

  // show meta mask modal if they aren't authenticated
  if (web3Required && !isAuthenticated) {
    // wrong network
    if (network) return <InvalidNetworkModal show />;

    // else no metamask
    return <MetaMaskModal show />;
  }

  return <Modal show {...data} />;
};

const mapStateToProps = state => ({
  name: state.modals.name,
  data: state.modals.data,
  isAuthenticated: isWeb3Authenticated(state),
  network: getNetwork(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);
