// @flow
import React, { Fragment } from "react";
import { connect } from "react-redux";

import * as selectors from "../../selectors/selectors";

import LemmaData from "../../components/lemma/LemmaData";
import LemmaEmptyState from "../../components/lemma/LemmaEmptyState";

type Props = {
  lemma: string,
  definition: string,
  id: string | number,
  category: string,
  owner: string,
  releaseId: number | string,
  isBase: boolean,
  isComposable: boolean,
  inCurrentRelease: boolean,
  compositionFee: number,
  offerValue: number,
  offerOwner: string,
  bidValue: number,
  bidOwner: string
};

// Lemma Data Container
const LemmaDataContainer = (props: Props) => {
  const { category, lemma, isBase, isComposable, inCurrentRelease } = props;

  if (category === "undefined") {
    return (
      <Fragment>
        <LemmaData {...props} />
        <LemmaEmptyState
          lemma={lemma}
          isBase={isBase}
          isComposable={isComposable}
          inCurrentRelease={inCurrentRelease}
        />
      </Fragment>
    );
  }

  return <LemmaData {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    id,
    definition: selectors.getLemmaDefinition(state, id),
    owner: selectors.getLemmaOwner(state, id),
    category: selectors.getLemmaCategory(state, id),
    compositionFee: selectors.getLemmaCompositionFee(state, id),
    releaseId: selectors.getLemmaReleaseId(state, id),
    isBase: selectors.isBaseLemma(state, id),
    isComposable: selectors.isLemmaComposable(state, id),
    inCurrentRelease: selectors.isLemmaInCurrentRelease(state, id),
    offerValue: selectors.getLemmaOfferValue(state, id),
    offerOwner: selectors.getLemmaOfferOwner(state, id),
    bidValue: selectors.getLemmaBidValue(state, id),
    bidOwner: selectors.getLemmaBidOwner(state, id)
  };
};
export default connect(mapStateToProps)(LemmaDataContainer);
