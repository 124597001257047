import {
  createAction,
  LEMMA_PAGE_LOAD,
  LEMMA_DATA,
  LEMMA_COMPOSE_MODAL_LOAD
} from "./action-types";

export const lemmaPageLoad = ({ id, lemma }) =>
  createAction(LEMMA_PAGE_LOAD, { id, lemma });

export const lemmaData = {
  request: id => createAction(LEMMA_DATA.REQUEST, { id }),
  success: (id, data) => createAction(LEMMA_DATA.SUCCESS, { id, data }),
  failure: (id, reason) =>
    createAction(LEMMA_DATA.FAILURE, {
      id,
      message: {
        type: "error",
        text: reason
      }
    })
};

export const lemmaComposeModalLoad = ({ lemma, id }) =>
  createAction(LEMMA_COMPOSE_MODAL_LOAD, { lemma, id });
