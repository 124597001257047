// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import { donate, modalClose } from "../../actions/actions";

type Props = {
  show: boolean,
  handleClose: () => void,
  handleSubmit: number => void
};

type State = {
  value: number
};

const Transition = props => <Slide direction="up" {...props} />;

class DonateModal extends Component<Props, State> {
  state = {
    value: 1
  };

  handleInput = event =>
    this.setState({
      value: event.target.value
    });

  render() {
    const { value } = this.state;

    const { show, handleClose, handleSubmit } = this.props;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="md"
        TransitionComponent={Transition}
        aria-labelledby="submit-donation-dialog-title"
        aria-describedby="submit-donation-dialog-actions"
      >
        <DialogTitle id="submit-donation-dialog-title">
          Help improve the lexiconomy!
        </DialogTitle>
        <DialogContent>
          <TextField
            id="donation-value"
            label="donation amount (eth)"
            value={value}
            placeholder="1"
            onChange={this.handleInput}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="large">
            cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(value);
              handleClose();
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={value <= 0}
          >
            donate
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  handleSubmit: value => dispatch(donate({ value })),
  handleClose: () => dispatch(modalClose("DonateModal"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonateModal);
