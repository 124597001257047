// @flow
import type { Saga } from "redux-saga";
import { takeLatest, put, call, select } from "redux-saga/effects";

import { WITHDRAW_BUTTON_LOAD } from "../actions/action-types";

import { withdrawFundsSuccess } from "../actions/actions";

import { getWeb3Account } from "../selectors/selectors";

import {
  LEXICONOMY_CONTRACT,
  getContractDetails
} from "../services/contracts/details";

import { contractCall } from "../services/contracts/transactions";
import { parseUInt256 } from "../services/utils";
/** *************************************************************************** */
/** *************************** Subroutines *********************************** */
/** *************************************************************************** */

function* fetchWithdrawFundsData() {
  try {
    // always make sure we have the cotnract data
    const { abi, address } = yield call(
      getContractDetails,
      LEXICONOMY_CONTRACT
    );

    // if we do not have contract data return
    if (!abi || !address) return;

    const account = yield select(getWeb3Account);

    // if we don't have an account return
    if (!account) return;

    const funds = yield call(contractCall, {
      abi,
      address,
      method: "compositionEarnings",
      params: [account]
    });

    yield put(
      withdrawFundsSuccess({
        funds: parseUInt256(funds)
      })
    );
  } catch (error) {
    console.error(error);
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

export function* watchCommonActions(): Saga<void> {
  yield takeLatest(WITHDRAW_BUTTON_LOAD, fetchWithdrawFundsData);
}
