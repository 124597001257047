import {
  createAction,
  COMMON_HEADER_CLICK,
  COMMON_SEARCH,
  HOME_BLOCK_CLICK,
  WITHDRAW_BUTTON_LOAD,
  WITHDRAW_BUTTON_CLICK,
  WITHDRAW_FUNDS_SUCCESS
} from "./action-types";

export const commonHeaderClick = name =>
  createAction(COMMON_HEADER_CLICK, { name });

export const commonSearch = lemma => createAction(COMMON_SEARCH, { lemma });

export const homeBlockClick = name => createAction(HOME_BLOCK_CLICK, { name });

export const withdrawButtonLoad = () => createAction(WITHDRAW_BUTTON_LOAD);

export const withdrawButtonClick = () => createAction(WITHDRAW_BUTTON_CLICK);

export const withdrawFundsSuccess = ({ funds }) =>
  createAction(WITHDRAW_FUNDS_SUCCESS, { funds });
