// @flow
import Web3 from "web3";

const INFURA_API = process.env.REACT_APP_INFURA_API;

const options = {
  // default is 50
  transactionBlockTimeout: 75,

  // default is 24, lets lower it for UX
  transactionConfirmationBlocks: 6
};
// given web3 provide OR use the Infura API
const web3 = new Web3(
  Web3.givenProvider || new Web3.providers.HttpProvider(INFURA_API),
  options
);

// use the same web3 object throughout our app
export default web3;
