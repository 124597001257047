import React from "react";
import withPageView from "../analytics/PageView";

import "./manifesto/Manifesto.css";

const ManifestoPage = () => (
  <div className="manifestoPage font-space-mono">
    <div id="manifesto" className="manifestoSection">
      <h1 className="manifestoTitle">
        manifesto{" "}
        <span role="img" aria-label="book">
          📖
        </span>
      </h1>
      <p className="manifestoSectionDetails">
        The lexiconomy is the world’s first decentralized and economized
        dictionary, empowering anyone to coin a word or phrase. It’s a
        dictionary for all languages, people, and cultures.
      </p>
      <p className="manifestoSectionDetails">
        Language is never static. Every day new words and phrases are invented
        while the meanings of old ones transform. As language evolves, the
        lexiconomy will serve as a persistent, immutable, and
        censorship-resistant record of the world's language. Over time, it will
        capture the evolution of the human language.
      </p>
      <p className="manifestoSectionDetails">
        The lexiconomy inherits its unique, unchanging, and uncensorable
        properties from the{" "}
        <a
          className="manifestoLink"
          href="https://etherscan.io/address/0x05dde4609035e464f995d13221b5166080634f21"
          target="_blank"
          rel="noopener noreferrer"
        >
          ethereum blockchain
        </a>
        . The lifespan of the lexiconomy is limited only by the life of
        ethereum. As a decentralized platform, the scope of the lexiconomy goes
        far beyond a web application. The permanency of the lexiconomy allows it
        to be a base language layer, or language API, for future decentralized
        applications to build upon.
      </p>
      <p className="manifestoSectionDetails">
        every entry in the lexiconomy is a called a{" "}
        <a
          className="manifestoLink"
          href="https://en.wikipedia.org/wiki/Lemma_(morphology)"
          target="_blank"
          rel="noopener noreferrer"
        >
          lemma
        </a>
        . a lemma can be a single letter, a long phrase, or a mathematical
        equation. the relationship between lemmas is recursive, one lemma can be
        composed of many smaller lemmas!
      </p>
      <p className="manifestoSectionDetails">
        Lemma ownership is in perpetuity and extends beyond the lexiconomy.
        Owners can freely sell, trade, and use their lemmas in any{" "}
        <a
          className="manifestoLink"
          href="http://erc721.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ERC 721-compatible
        </a>{" "}
        application. Within the lexiconomy, owners are rewarded for their
        ingenuity and lexical contributions. Every time lemmas are used to
        compose a new lemma, the owners of the used lemmas are compensated.
      </p>
      <p className="manifestoSectionDetails">
        The possibilities of the lexiconomy are only bounded by language. As
        language is created, defined, and controlled by people, the lexiconomy
        will also be defined and controlled by the imagination of people all
        over the world.
      </p>
    </div>
  </div>
);

export default withPageView("Manifesto")(ManifestoPage);
