// @flow
import {
  getNetworkName,
  hasWeb3Provider,
  isProduction,
  defaultNetwork
} from "../utils";

// need to figure out a way for this to be dynaimc
// the abi will change with the contract
import lexiconomyAbi from "./abis/lexiconomy.json";
import saleClockAuctionAbi from "./abis/sale_clock_auction.json";

const lexiconomyAddress = isProduction
  ? "0x05DdE4609035E464F995D13221B5166080634f21"
  : "0x2Ca26547225a73B982b94D743cDe216a8002A23E";

const saleClockAuctionAddress = isProduction
  ? "0xA271B8830cBD9a3c531f75Df8c0a2792eAcDD676"
  : "0x45a0364D32D01c36E8c0Bc1bCf9d1E7a8A18b925";

export const LEXICONOMY_CONTRACT = "LexiconomyCore";
export const SALE_CONTRACT = "SaleClockAuction";

// fetch contract details or return cached results
export type Contracts = "LexiconomyCore" | "SaleClockAuction";

const details = {};

const backUpDetails = {
  [LEXICONOMY_CONTRACT]: {
    abi: lexiconomyAbi,
    address: lexiconomyAddress
  },
  [SALE_CONTRACT]: {
    abi: saleClockAuctionAbi,
    address: saleClockAuctionAddress
  }
};

const query = `query getContractDetails($network: String!, $name: String!) {
  contract(network: $network, name: $name) {
    address
    abi
  }
}`;

const fetchContract = (name, network = defaultNetwork) =>
  new Promise(resolve =>
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        query,
        variables: { name, network }
      })
    })
      .then(r => r.json())
      .then(({ data: { contract }, errors }) => {
        if (errors) {
          // dynamic import harcdoded by name
          return resolve(backUpDetails[name]);
        }

        const { address, abi } = contract;
        const data = {
          abi: JSON.parse(abi),
          address
        };

        details[name] = data;

        return resolve(data);
      })
      .catch(error => resolve(backUpDetails[name]))
  );

// fetch contract details or return cached results
export const getContractDetails = async (name: Contracts) => {
  if (details[name]) return details[name];

  const network = hasWeb3Provider() ? await getNetworkName() : defaultNetwork;

  // save promise
  details[name] = fetchContract(name, network);

  return details[name];
};
