// @flow
import React from "react";
import { connect } from "react-redux";

import Snackbar from "@material-ui/core/Snackbar";

import { messageClose } from "../../actions/actions";

import MessageBar from "../../components/message/MessageBar";

type Variant = "success" | "warning" | "error" | "info";

type Props = {
  open: boolean,
  kind: Variant,
  text: string,
  onClose: () => void
};

const MessageBarContainer = ({
  open = false,
  kind = "info",
  text = "",
  onClose
}: Props) => {
  // only close on explict clicks
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      onExited={onClose}
    >
      <MessageBar onClose={handleClose} variant={kind} message={text} />
    </Snackbar>
  );
};

const mapStateToProps = state => ({
  open: state.messages.open,
  kind: state.messages.kind,
  text: state.messages.text
});

const mapDispatchToProps = {
  onClose: messageClose
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageBarContainer);
