// @flow
import React from "react";
import { 
  displayAddress,
  displayWeiValueInEther, 
  getEtherscanURL,
  LEX_TOKEN_ID,
  makeLemmaIdReadable,
} from "../../services/utils";

import "./LemmaData.css";

type Props = {
  category: string,
  id: number | string,
  owner: string,
  releaseId: number | string,
  definition: string,
  compositionFee: number,
  offerValue: number,
  offerOwner: string,
  bidValue: number,
  bidOwner: string
};

const getDescription = ({ category }) => {
  switch (category) {
    case "illegal":
      return (
        <div className="lemma-description">
          <div className="lemma-description-title">
            You found a illegal word &nbsp;
            <span role="img" aria-label="uh-oh emoji">
              😳
            </span>
          </div>
          <div className="lemma-description-explained">
            <a href="/tips#illegal">Illegal words</a> can never be owned nor
            used to compose a phrase.
          </div>
        </div>
      );
    case "whitespace":
    case "public":
      return (
        <div className="lemma-description">
          <div className="lemma-description-title">
            You found a public word!
          </div>
          <div className="lemma-description-explained">
            <a href="/tips#public">Public words</a> can be used by everyone to
            compose new phrases.
          </div>
        </div>
      );
    default:
      return null;
  }
};

const displayDataSection = (label, value) => (
  <div className="lemma-data-section">
    <div className="lemma-data-label">{label}</div>
    <div className="lemma-data-value">{value}</div>
  </div>
);

const DataSection = ({label, value, link}) => (
  link ? (
  <a className="lemma-data-section link" href={link} target="_blank">
    <div className="lemma-data-label">{label}</div>
    <div className="lemma-data-value">{value}</div>
  </a>
  ) :
  <div className="lemma-data-section">
    <div className="lemma-data-label">{label}</div>
    <div className="lemma-data-value">{value}</div>
  </div>
);

// Lemma data from the contract (id, category, release id, owner)
const LemmaData = ({
  category,
  id,
  owner,
  releaseId,
  definition,
  compositionFee,
  offerValue,
  offerOwner,
  bidValue,
  bidOwner
}: Props) => {
  const description = getDescription({
    category
  });

  // create owner url 
  const ownerURL = `${getEtherscanURL}/address/${owner}`
  // create release url
  const releaseURL = `/release/${releaseId}`

  // create token id url
  const tokenURL = `${getEtherscanURL}/token/${LEX_TOKEN_ID}?a=${id}`

  return (
    // display data
    <div>
      <div className="lemma-data-container">
        {category === "defined" &&
          <DataSection label="owned by" value={displayAddress(owner)} link={ownerURL} />
            }
        {category === "defined" &&
          <DataSection label="release number" value={`#${releaseId}`} link={releaseURL} />
        }
        {category === "defined" &&
          <DataSection label="token id" value={makeLemmaIdReadable(id, 8)} link={tokenURL} />
        }
        {!["illegal", "undefined"].includes(category) &&
          displayDataSection(
            "composition fee",
            displayWeiValueInEther(compositionFee)
          )}
        {bidValue > 0 &&
          displayDataSection(
            `highest bid`,
            <span>
              {displayWeiValueInEther(bidValue)} from {displayAddress(bidOwner)}
            </span>
          )}
        {offerValue > 0 &&
          displayDataSection(
            `purchase offer`,
            <span>
              {displayWeiValueInEther(offerValue)} from{" "}
              {displayAddress(offerOwner)}
            </span>
          )}
      </div>
      {/* Display definition and description last */}
      {definition && displayDataSection("definition", definition)}
      {description}
    </div>
  );
};

export default LemmaData;
