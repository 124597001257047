import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "../reducers/rootReducer";
import { isProduction } from "../services/utils";

export const history = createBrowserHistory();

const composeEnhancers =
  !isProduction &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history) // for dispatching history actions
      )
    )
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
