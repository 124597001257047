import {
  createAction,
  RELEASE_PAGE_LOAD,
  RELEASE_PAGE_ID,
  RELEASE_DATA,
  RELEASE_CURRENT_DATA,
  RELEASE_TIMER_LOAD,
  RELEASE_TIMER_SUCCESS,
  RELEASE_TIMER_CLICK,
  RELEASE_BLOCK_CLICK
} from "./action-types";

export const releasePageLoad = ({ id }) =>
  createAction(RELEASE_PAGE_LOAD, { id });

export const releaseData = {
  request: id => createAction(RELEASE_DATA.REQUEST, { id }),
  success: (id, data) => createAction(RELEASE_DATA.SUCCESS, { id, data }),
  failure: (id, reason) =>
    createAction(RELEASE_DATA.FAILURE, {
      id,
      message: {
        type: "error",
        text: reason
      }
    })
};

export const releasePageId = id => createAction(RELEASE_PAGE_ID, { id });

export const releaseCurrentData = {
  request: () => createAction(RELEASE_CURRENT_DATA.REQUEST),
  success: data => createAction(RELEASE_CURRENT_DATA.SUCCESS, { data }),
  failure: reason =>
    createAction(RELEASE_CURRENT_DATA.FAILURE, {
      message: {
        type: "error",
        text: reason
      }
    })
};

export const releaseTimerLoad = () => createAction(RELEASE_TIMER_LOAD);

export const releaseTimerSuccess = payload =>
  createAction(RELEASE_TIMER_SUCCESS, payload);

export const releaseTimerClick = time =>
  createAction(RELEASE_TIMER_CLICK, { time });

export const releaseBlockClick = ({ lemma, price }) =>
  createAction(RELEASE_BLOCK_CLICK, { lemma, price });
