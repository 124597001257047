// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { distanceInWordsStrict } from "date-fns";

import * as selectors from "../../selectors/selectors";

import {
  getBlockTimestamp,
  getGraphColor,
  getLemmaColor
} from "../../services/utils";

import LemmaChart from "../../components/lemma/LemmaChart";

type OwnProps = {
  lemma: string,
  id: string | number,
  auctionType: "sale"
};

type StateProps = {|
  +startingPrice: number,
  +endingPrice: number,
  +duration: number,
  +startedAt: number,
  +endsAt: number,
  +color: string,
  +backgroundColor: string
|};

type Props = OwnProps & StateProps;

type State = {
  // interval id to update time remainig and current price
  intervalId: ?IntervalID,
  currentPrice: number,
  currentTime: number,
  timeRemaining: string
};

class LemmaChartContainer extends Component<Props, State> {
  state = {
    intervalId: null,
    currentPrice: 0,
    currentTime: 0,
    timeRemaining: ""
  };

  componentDidMount() {
    this.updateTimeAndPrice();

    const intervalId = setInterval(() => this.updateTimeAndPrice(), 1000);

    this.setState(prevState => ({
      ...prevState,
      intervalId
    }));
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId) clearInterval(intervalId);
  }

  updateTimeAndPrice = async () => {
    const {
      startingPrice,
      endingPrice,
      duration,
      startedAt,
      endsAt
    } = this.props;

    // rate of change in seconds
    const rateOfChange = (startingPrice - endingPrice) / duration;

    // current time
    const now = await getBlockTimestamp();

    // if we can't get the current timestamp, do nothing
    if (!now) return;

    // time elapsed in seconds
    const timeElapsed = now - startedAt;

    const value = startingPrice - timeElapsed * rateOfChange;

    const roundedValue = parseFloat(value.toFixed(8));
    const timeRemaining =
      endsAt > now
        ? `${distanceInWordsStrict(
            new Date(now * 1000),
            new Date(endsAt * 1000)
          )} remaining`
        : "Auction is over";

    this.setState({
      currentPrice: roundedValue,
      currentTime: parseInt(now, 10),
      timeRemaining
    });
  };

  render() {
    const { currentPrice, currentTime, timeRemaining } = this.state;

    const {
      startingPrice,
      endingPrice,
      startedAt,
      endsAt,
      color,
      backgroundColor
    } = this.props;

    if (!currentTime) return null;

    return (
      // calculate chart colors

      <LemmaChart
        startTime={startedAt}
        currentTime={currentTime}
        endTime={endsAt}
        startingPrice={startingPrice}
        currentPrice={currentPrice}
        endingPrice={endingPrice}
        timeRemaining={timeRemaining}
        color={color}
        backgroundColor={backgroundColor}
      />
    );
  }
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  const { id, lemma, auctionType } = ownProps;

  const color = getGraphColor(lemma);
  const backgroundColor = getLemmaColor(lemma);

  const duration = selectors.getAuctionDuration(state, auctionType, id);
  const startedAt = selectors.getAuctionStartTime(state, auctionType, id);
  const endsAt = startedAt + duration;

  return {
    startingPrice: selectors.getAuctionStartingPrice(state, auctionType, id),
    endingPrice: selectors.getAuctionEndingPrice(state, auctionType, id),
    duration,
    startedAt,
    endsAt,
    color,
    backgroundColor
  };
};

export default connect(mapStateToProps)(LemmaChartContainer);
