// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import withPageView from "../analytics/PageView";

import { releasePageLoad } from "../actions/actions";
import * as selectors from "../selectors/selectors";

import { hasValidNetwork } from "../services/utils";

import Loading from "../components/common/Loading";
import InvalidNetwork from "../components/common/InvalidNetwork";

import ReleaseDataContainer from "./release/ReleaseDataContainer";
import ReleasePagination from "./release/ReleasePagination";
import RelaseTitle from "../components/release/ReleaseTitle";

import "../components/release/Release.css";

type Props = {
  isLoading: boolean,
  fetchData: Object => void,
  id: string,
  title: string,
  isCached: boolean
};

type State = {
  validNetwork: boolean
};

// Lemma Page Container Component
class ReleasePage extends Component<Props, State> {
  state = {
    validNetwork: true
  };

  // on mount get release id and start saga to load bids
  async componentDidMount() {
    const { fetchData, id } = this.props;
    // TODO: Reponsible for not fetching more data if data exists
    fetchData({ id });

    const validNetwork = await hasValidNetwork();

    this.setState({
      validNetwork
    });
  }

  // fetch data if on a new release page
  componentDidUpdate(prevProps) {
    const { fetchData, id } = this.props;
    if (prevProps.id !== id) {
      // TODO: Reponsible for not fetching more data if data exists?
      fetchData({ id });
    }
  }

  render() {
    const { validNetwork } = this.state;
    const { isLoading = true, title, isCached } = this.props;

    if (!validNetwork) {
      return <InvalidNetwork />;
    }
    // Loading and we have no data
    if (isLoading && !isCached && !title) {
      // return loading screen
      return <Loading />;
    }

    // Loading but we a title
    if (isLoading && !isCached && title) {
      return (
        <div className="release-page">
          <RelaseTitle id={title} />
          <div className="release-page-content">
            <Loading />
          </div>
        </div>
      );
    }

    return (
      <div className="release-page">
        <RelaseTitle id={title} />
        <div className="release-page-content">
          <ReleaseDataContainer />
        </div>
        <div className="release-page-footer">
          <ReleasePagination />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: pageParamId } = ownProps.match.params;

  const releaseId = selectors.getPageReleaseId(state);

  const title = pageParamId || releaseId;

  const isCached = !isEmpty(selectors.getReleaseBids(state, releaseId));

  // get page id
  return {
    isLoading: selectors.isLoadingReleaseData(state, title),
    id: pageParamId,
    title,
    isCached
  };
};

export default withPageView("Release")(
  withRouter(
    connect(
      mapStateToProps,
      { fetchData: releasePageLoad }
    )(ReleasePage)
  )
);
