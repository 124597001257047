// @flow
import React from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

import { modalClose } from "../../actions/actions";

// import "./InvalidNetworkModal.css";

type Props = {
  show: boolean,
  onClose: () => void
};

const Transition = props => <Slide direction="up" {...props} />;

const InvalidNetworkModal = ({ show, onClose }: Props) => (
  <Dialog
    open={show}
    onClose={onClose}
    maxWidth="sm"
    TransitionComponent={Transition}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Unsupported Ethereum Network{" "}
      <span role="img" aria-label="Stop">
        🛑
      </span>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        To explore the lexiconomy, you must connect to main ethereum network.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" size="large">
        got it
      </Button>
    </DialogActions>
  </Dialog>
);

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(modalClose("InvalidNetworkModal"))
});

export default connect(
  null,
  mapDispatchToProps
)(InvalidNetworkModal);
