import {
  createAction,
  MESSAGE_CLOSE,
  MESSAGE_OPEN,
  MODAL_OPEN,
  MODAL_CLOSE
} from "./action-types";

export const messageClose = () => createAction(MESSAGE_CLOSE, {});

export const messageOpen = ({ kind, text }) =>
  createAction(MESSAGE_OPEN, { kind, text });

export const modalOpen = ({ name, data }: Object) =>
  createAction(MODAL_OPEN, { name, data });

export const modalClose = name => createAction(MODAL_CLOSE, { name });
