// @flow
import React from "react";
import DatePicker from "react-datepicker";

import TextField from "@material-ui/core/TextField";

import "react-datepicker/dist/react-datepicker.css";

import "./AuctionDurationPicker.css";

type Props = {|
  +selected: ?Date,
  +handleChange: Date => void,
  +error?: boolean
|};

const now = new Date();
const fiveYears = new Date(
  now.getFullYear() + 5,
  now.getMonth(),
  now.getDate()
);

// to satify React + react-datepicker, need to wrap this in a class
class TextFieldWithRef extends React.Component {
  render() {
    return (
      <TextField
        fullWidth
        id="sale-end-time"
        label="sale end time"
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        error={this.props.error}
        onClick={this.props.onClick}
        value={this.props.value}
      />
    );
  }
}

const AuctionDurationPicker = ({
  selected,
  handleChange,
  error = false
}: Props) => (
  <div className="datepickerWrap">
    <DatePicker
      customInput={<TextFieldWithRef error={error} />}
      className="date-input"
      selected={selected}
      onChange={handleChange}
      showTimeSelect
      timeFormat="h:mm aa"
      timeIntervals={15}
      dateFormat="MMMM d, yyyy h:mm aa"
      timeCaption="time"
      minDate={now}
      maxDate={fiveYears}
      placeholderText="Select an end time for the auction"
    />
    {error && (
      <div className="date-error">
        End time must be at least three minutes from now
      </div>
    )}
  </div>
);

export default AuctionDurationPicker;
