import {
  MODAL_OPEN,
  MODAL_CLOSE,
  RELEASE_PAGE_LOAD,
  RELEASE_TIMER_CLICK,
  RELEASE_BLOCK_CLICK,
  LEMMA_PAGE_LOAD,
  MESSAGE_OPEN,
  COMMON_SEARCH,
  COMMON_HEADER_CLICK,
  HOME_BLOCK_CLICK,
  WITHDRAW_BUTTON_CLICK
} from "../actions/action-types";

const mapActionsToEvents = {
  [MODAL_OPEN]: {
    event: "modal",
    data: { action: "Open" }
  },

  [MODAL_CLOSE]: {
    event: "modal",
    data: { action: "Close" }
  },

  [LEMMA_PAGE_LOAD]: {
    event: "lemmaPage"
  },

  [RELEASE_PAGE_LOAD]: {
    event: "releasePage"
  },

  [MESSAGE_OPEN]: {
    event: "userMessage"
  },

  [COMMON_SEARCH]: {
    event: "search"
  },

  [RELEASE_BLOCK_CLICK]: {
    event: "releaseBlock"
  },

  [HOME_BLOCK_CLICK]: {
    event: "homeBlocks"
  },

  [RELEASE_TIMER_CLICK]: {
    event: "releaseTimer"
  },

  [COMMON_HEADER_CLICK]: {
    event: "headerLink"
  },
  [WITHDRAW_BUTTON_CLICK]: {
    event: "withdrawButton"
  }
};

export default mapActionsToEvents;
