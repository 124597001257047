// @flow
import React from "react";
import { Link } from "react-router-dom";

import {
  weiToEther,
  displayAddress,
  getLemmaColor
} from "../../services/utils";

import "./Card.css";

type Props = {
  title: string,
  price: string | number,
  address: string,
  destination: string,
  onClick: ?Function
};

const Card = ({ title, price, address, destination, onClick }: Props) => (
  <Link
    className="card"
    to={destination}
    style={{ backgroundColor: getLemmaColor(title) }}
    onClick={onClick}
  >
    <div className="card-title">{title}</div>
    <div className="card-details">{weiToEther(price)} eth</div>
    <div className="card-details">{displayAddress(address)}</div>
  </Link>
);

export default Card;
