import {
  MESSAGE_OPEN,
  MESSAGE_CLOSE,
  MODAL_OPEN,
  MODAL_CLOSE
} from "../actions/action-types";

const INITIAL_STATE = { open: false };

export const messagesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MESSAGE_OPEN:
      // add the message data
      return { ...state, ...payload, open: true };
    case MESSAGE_CLOSE:
      // Reset the message state
      return { ...state, open: false };
    default:
  }
  return state;
};

export const modalsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MODAL_OPEN:
      // set the state to the modal
      return { ...payload };
    case MODAL_CLOSE:
      // Reset the modal state
      return {};
    default:
  }
  return state;
};
