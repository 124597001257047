// @flow
import React from "react";
import { connect } from "react-redux";

import * as selectors from "../../selectors/selectors";
import {
  modalOpen,
  cancelSaleAuction,
  acceptOffer,
  cancelOffer
} from "../../actions/actions";
import { weiToEther } from "../../services/utils";

import Button from "../../components/common/Button";

import "../../components/lemma/Lemma.css";

type Props = {
  defined: boolean,
  hasDefinition: boolean,
  category: string,
  isOwner: boolean,
  isBase: boolean,
  isComposable: boolean,
  isSeller: boolean,
  liveSaleAuction: boolean,
  inCurrentRelease: boolean,
  offerValue: number,
  isOfferOwner: boolean,
  releaseIsLive: boolean,
  openTransactionModal: string => void,
  openCancelSaleModal: void => void,
  openAcceptOfferModal: number => void
};

// Lemma Actions Container
// Decide what actions to display from the state
const LemmaActionsContainer = ({
  defined,
  hasDefinition,
  category,
  offerValue,
  isOwner,
  isOfferOwner,
  isBase,
  isComposable,
  isSeller,
  inCurrentRelease,
  liveSaleAuction,
  releaseIsLive,
  openTransactionModal,
  openCancelSaleModal,
  openAcceptOfferModal,
  openCancelOfferModal
}: Props) => {
  // do not show actions for public, whitespace, or illegal lemmas
  if (["illegal", "public", "whitespace"].includes(category)) return null;

  const showBid =
    isBase && (!inCurrentRelease || (inCurrentRelease && releaseIsLive));

  // Cases:
  // Undefined => Bid or Compose
  const undefinedActions = (
    <div className="lemma-actions-container">
      {showBid && (
        <Button onClick={() => openTransactionModal("BidModal")}>
          {inCurrentRelease ? "out bid" : "bid"}
        </Button>
      )}
      {isComposable &&
        !inCurrentRelease && (
          <Button
            onClick={() => openTransactionModal("ComposeModal")}
            disabled={!isComposable}
          >
            compose
          </Button>
        )}
    </div>
  );

  // Defined & Not Owned => Buy (disabled if no current auction)
  const definedNotOwnedActions = (
    <div className="lemma-actions-container">
      {/* if they are seller, give the option to cancel */}
      {isSeller ? (
        <Button onClick={openCancelSaleModal} disabled={false}>
          cancel sale auction
        </Button>
      ) : (
        liveSaleAuction && (
          <Button onClick={() => openTransactionModal("BuyModal")}>
            buy now
          </Button>
        )
      )}
      {/* if they are seller, give the option to cancel */}
      {isOfferOwner
        ? !isSeller && (
            <Button onClick={openCancelOfferModal} disabled={false}>
              withdraw offer
            </Button>
          )
        : !isSeller && (
            <Button onClick={() => openTransactionModal("MakeOfferModal")}>
              make offer
            </Button>
          )}
    </div>
  );

  // Defined & Owned => Sell
  const definedOwnedActions = (
    <div className="lemma-actions-container">
      <Button
        onClick={() => openTransactionModal("SellModal")}
        disabled={liveSaleAuction}
      >
        sell
      </Button>
      <Button onClick={() => openTransactionModal("DefineModal")}>
        {hasDefinition ? "redefine" : "define"}
      </Button>
      <Button onClick={() => openTransactionModal("CompositionFeeModal")}>
        change composition fee
      </Button>
      {offerValue > 0 && (
        <Button onClick={() => openAcceptOfferModal(offerValue)}>
          accept offer
        </Button>
      )}
    </div>
  );

  // fancy syntax for switching between two variables and assigning its value \
  const actions = (() => {
    switch (`${defined}|${isOwner}`) {
      // Defined & Not Owned
      case "true|false":
        return definedNotOwnedActions;
      // Owner
      case "true|true":
        return definedOwnedActions;
      // Undefined
      case "false|false":
      case "false|true":
      default:
        return undefinedActions;
    }
  })();

  return actions;
};

const mapStateToProps = (state, { id }) => ({
  defined: selectors.isLemmaDefined(state, id),
  hasDefinition: Boolean(selectors.getLemmaDefinition(state, id)),
  category: selectors.getLemmaCategory(state, id),
  offerValue: selectors.getLemmaOfferValue(state, id),
  isOwner: selectors.isLemmaOwner(state, id),
  isOfferOwner: selectors.isLemmaOfferOwner(state, id),
  isSeller: selectors.isAuctionSeller(state, "sale", id),
  liveSaleAuction: selectors.isAuctionLive(state, "sale", id),
  isBase: selectors.isBaseLemma(state, id),
  isComposable: selectors.isLemmaComposable(state, id),
  inCurrentRelease: selectors.isLemmaInCurrentRelease(state, id),
  releaseIsLive: selectors.getCurrentReleaseTimeRemaining(state) > 0
});

const mapDispatchToProps = (dispatch, { id, lemma }) => ({
  openTransactionModal: (name, data = {}) =>
    dispatch(modalOpen({ name, data: { lemma, id, ...data } })),
  openCancelSaleModal: () =>
    dispatch(
      modalOpen({
        name: "ComfirmationModal",
        data: {
          title: (
            <span>
              cancel the sale auction for <strong>{lemma}</strong>
            </span>
          ),
          handleSubmit: () => dispatch(cancelSaleAuction({ id }))
        }
      })
    ),
  openCancelOfferModal: () =>
    dispatch(
      modalOpen({
        name: "ComfirmationModal",
        data: {
          title: (
            <span>
              withdraw offer for <strong>{lemma}</strong>
            </span>
          ),
          handleSubmit: () => dispatch(cancelOffer({ id }))
        }
      })
    ),
  openAcceptOfferModal: offerValue =>
    dispatch(
      modalOpen({
        name: "ComfirmationModal",
        data: {
          title: (
            <span>
              sell <strong>{lemma}</strong> for {weiToEther(offerValue)} eth
            </span>
          ),
          handleSubmit: () => dispatch(acceptOffer({ id }))
        }
      })
    )
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LemmaActionsContainer);
