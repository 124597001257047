// @flow
import React from "react";

import AreaChart from "recharts/lib/chart/AreaChart";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

import Tooltip from "recharts/lib/component/Tooltip";
import XAxis from "recharts/lib/cartesian/XAxis";
import YAxis from "recharts/lib/cartesian/YAxis";
import Area from "recharts/lib/cartesian/Area";

import { displayEtherValue } from "../../services/utils";

import "./LemmaChart.css";

type Props = {|
  +timeRemaining: string,
  +startTime: number,
  +currentTime: number,
  +endTime: number,
  +startingPrice: number,
  +currentPrice: number,
  +endingPrice: number,
  +color: string,
  +backgroundColor: string
|};

const LemmaChart = ({
  timeRemaining,
  startTime,
  currentTime,
  endTime,
  startingPrice,
  endingPrice,
  currentPrice,
  color = "#6eb5ff",
  backgroundColor = "#fff"
}: Props) => {
  const data = [
    {
      name: "Starting Price",
      value: startingPrice,
      time: startTime
    },
    {
      name: "Current Price",
      value: currentPrice,
      time: currentTime
    },
    {
      name: "Ending Price",
      value: endingPrice,
      time: endTime
    }
  ];

  // TODOs
  // color
  // render dots
  return (
    <div className="lemmaChartContainer" style={{ backgroundColor }}>
      <div className="lemmaChartDetails">
        <div className="lemmaChartTitle">Sale Auction</div>
        <div className="lemmaChartExtraDetails">
          <div>
            <strong>{displayEtherValue(currentPrice)}</strong>
          </div>
          <div>{timeRemaining}</div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={250}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="time"
            tick={false}
            label="auction time"
            scale="time"
            type="number"
            domain={["dataMin", "dataMax"]}
          />
          <YAxis />
          <Tooltip
            separator={null}
            labelFormatter={time => {
              if (time === startTime) return "Starting Price";
              if (time === currentTime) return "Current Price";
              return "End Price";
            }}
          />
          <Area
            type="linear"
            dot
            dataKey="value"
            unit=" eth"
            stroke="#8884d8"
            name=" "
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
export default LemmaChart;
