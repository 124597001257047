// @flow
import React, { type Node } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

import { modalClose } from "../../actions/actions";

type Props = {
  title: Node,
  content: string,
  handleClose: () => void,
  handleSubmit: void => void
};

const Transition = props => <Slide direction="up" {...props} />;

const ComfirmationModal = ({
  title,
  content,
  handleClose,
  handleSubmit
}: Props) => (
  <Dialog
    open
    onClose={handleClose}
    TransitionComponent={Transition}
    aria-labelledby="comfirmation-dialog-title"
    aria-describedby="comfirmation-dialog-actions"
  >
    <DialogTitle id="comfirmation-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="comfirmation-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary" size="large">
        cancel
      </Button>
      <Button
        onClick={() => {
          handleSubmit();
          handleClose();
        }}
        color="primary"
        autoFocus
        size="large"
      >
        comfirm
      </Button>
    </DialogActions>
  </Dialog>
);

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(modalClose("ComfirmationModal"))
});

export default connect(
  null,
  mapDispatchToProps
)(ComfirmationModal);
