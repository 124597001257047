// @flow
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { homeBlockClick } from "../../actions/actions";

import randomWord from "../../services/random-words";

import "./HomeBlocks.css";

type Props = {|
  track: string => void
|};

const HomeBlocks = ({ track }: Props) => (
  <div className="lexiconomy-home-blocks font-space-mono">
    <Link
      onClick={() => track("manifesto")}
      className="lexiconomy-home-block"
      to="/manifesto"
    >
      manifesto
    </Link>
    <Link
      onClick={() => track("user guide")}
      className="lexiconomy-home-block"
      to="/tips"
    >
      guide
    </Link>
    <Link
      onClick={() => track("release")}
      className="lexiconomy-home-block"
      to="/release/current"
    >
      releases
    </Link>
    <Link
      onClick={() => track("random")}
      className="lexiconomy-home-block"
      to={`/lemma/${encodeURIComponent(encodeURIComponent(randomWord()))}`}
    >
      explore
    </Link>
  </div>
);

// sloppy sorry
const mapDispatchToProps = dispatch => ({
  track: name => dispatch(homeBlockClick(name))
});

export default connect(
  null,
  mapDispatchToProps
)(HomeBlocks);
