const randomWords = [
  "sore",
  "fuzzy",
  "false",
  "suggest",
  "ink",
  "clumsy",
  "eggnog",
  "gamy",
  "lazy",
  "limit",
  "friendly",
  "wire",
  "approval",
  "advise",
  "difficult",
  "zucked",
  "earth",
  "cats",
  "brown",
  "boorish",
  "barbarous",
  "flashy",
  "sophisticated",
  "snails",
  "heartbreaking",
  "occur",
  "laugh",
  "direful",
  "workable",
  "cat",
  "brush",
  "instinctive",
  "babies",
  "elite",
  "mourn",
  "endurable",
  "group",
  "wanting",
  "pet",
  "invincible",
  "playground",
  "kitty",
  "size",
  "chivalrous",
  "coach",
  "savory",
  "macabre",
  "tested",
  "strip",
  "many",
  "kindly",
  "amuse",
  "representative",
  "pinch",
  "quizzical",
  "carry",
  "push",
  "sun",
  "apathetic",
  "pigs",
  "arrive",
  "ajar",
  "general",
  "noisy",
  "complex",
  "clam",
  "friend",
  "weigh",
  "dare",
  "yell",
  "squeamish",
  "tap",
  "nail",
  "tramp",
  "feigned",
  "interesting",
  "cloudy",
  "stem",
  "steep",
  "dynamic",
  "puny",
  "pig",
  "tray",
  "yawn",
  "abject",
  "jumpy",
  "vanish",
  "ill",
  "dinosaurs",
  "snobbish",
  "edge",
  "stop",
  "equal",
  "person",
  "roof",
  "empty",
  "action",
  "splendid",
  "mysterious",
  "unhealthy",
  "riddle",
  "dizzy",
  "unfasten",
  "hobbies",
  "nest",
  "pretty",
  "interrupt",
  "crazy",
  "long-term",
  "scrub",
  "trees",
  "marked",
  "volatile",
  "need",
  "six",
  "education",
  "move",
  "flower",
  "spring",
  "lively",
  "tomatoes",
  "honey",
  "oranges",
  "cuddly",
  "gold",
  "dirty",
  "spotless",
  "mice",
  "burst",
  "thankful",
  "sneeze",
  "country",
  "clean",
  "gainful",
  "elated",
  "remove",
  "pushy",
  "wipe",
  "home",
  "ambiguous",
  "tempt",
  "disagreeable",
  "handy",
  "roomy",
  "pause",
  "creature",
  "sour",
  "fluttering",
  "tangible",
  "pack",
  "belief",
  "dirt",
  "bury",
  "greedy",
  "coherent",
  "bite",
  "eatable",
  "wren",
  "haunt",
  "graceful",
  "support",
  "beef",
  "freezing",
  "contain",
  "white",
  "waggish",
  "sweater",
  "nest",
  "believe",
  "soap",
  "quaint",
  "animal",
  "fit",
  "capricious",
  "combative",
  "perpetual",
  "jail",
  "unlock",
  "chilly",
  "rapid",
  "applaud",
  "oval",
  "stage",
  "bumpy",
  "naive",
  "idiotic",
  "name",
  "rock",
  "possess",
  "x-ray",
  "enter",
  "judge",
  "wash",
  "afterthought",
  "impossible",
  "frighten",
  "jam",
  "wait",
  "jump",
  "approve",
  "smell",
  "brass",
  "grain",
  "miss",
  "plan",
  "embarrass",
  "string",
  "open",
  "grape",
  "disarm",
  "stream",
  "deafening",
  "shrill",
  "lackadaisical",
  "whispering",
  "church",
  "faded",
  "complete",
  "try",
  "planes",
  "hypnotic",
  "mist",
  "lush",
  "quiet",
  "surround",
  "destruction",
  "nice",
  "test",
  "fixed",
  "delay",
  "bucket",
  "hard-to-find",
  "oatmeal",
  "nation",
  "abounding",
  "murder",
  "talented",
  "laughable",
  "analyse",
  "greasy",
  "bomb",
  "abandoned",
  "handsomely",
  "full",
  "plantation",
  "car",
  "joke",
  "saw",
  "breezy",
  "solid",
  "grip",
  "better",
  "tight",
  "reading",
  "pine",
  "decisive",
  "quarrelsome",
  "flavor",
  "introduce",
  "wrestle",
  "celery",
  "unsuitable",
  "penitent",
  "watery",
  "grandfather",
  "terrible",
  "trouble",
  "aboard",
  "vegetable",
  "extend",
  "employ",
  "star",
  "level",
  "screeching",
  "glamorous",
  "summer",
  "impulse",
  "cakes",
  "fold",
  "invention",
  "neat",
  "tense",
  "powerful",
  "obese",
  "groovy",
  "drunk",
  "half",
  "striped",
  "hand",
  "scene",
  "thumb",
  "succeed",
  "pear",
  "tricky",
  "start",
  "structure",
  "room",
  "leg",
  "fallacious",
  "kittens",
  "self",
  "tug",
  "amuck",
  "hysterical",
  "sulky",
  "question",
  "glow",
  "sock",
  "fog",
  "wish",
  "rob",
  "knife",
  "melodic",
  "square",
  "amused",
  "panoramic",
  "deserted",
  "boat",
  "probable",
  "hat",
  "cough",
  "mine",
  "apologise",
  "note",
  "cooperative",
  "sturdy",
  "dangerous",
  "thank",
  "safe",
  "silky",
  "cracker",
  "scarecrow",
  "zoo",
  "kneel",
  "explain",
  "upset",
  "motionless",
  "wrench",
  "share",
  "pipe",
  "land",
  "guitar",
  "thirsty",
  "sleepy",
  "uneven",
  "soft",
  "wrap",
  "property",
  "industrious",
  "ban",
  "color",
  "trite",
  "zealous",
  "rhythm",
  "burly",
  "nauseating",
  "round",
  "delicious",
  "decorous",
  "abrasive",
  "blade",
  "library",
  "corn",
  "shy",
  "roll",
  "nondescript",
  "curl",
  "blushing",
  "steer",
  "sound",
  "rain",
  "found",
  "colorful",
  "labored",
  "disastrous",
  "deep",
  "smooth",
  "peck",
  "lunchroom",
  "battle",
  "slow",
  "dazzling",
  "caring",
  "swanky",
  "sheet",
  "dark",
  "damaging",
  "undress",
  "plane",
  "ablaze",
  "texture",
  "jog",
  "whip",
  "pump",
  "word",
  "addicted",
  "elderly",
  "live",
  "bell",
  "nappy",
  "guess",
  "sweet",
  "week",
  "gabby",
  "plastic",
  "spectacular",
  "hot",
  "pricey",
  "tiny",
  "flood",
  "paste",
  "accessible",
  "statement",
  "milk",
  "spiritual",
  "tan",
  "silent",
  "berserk",
  "spare",
  "icy",
  "bubble",
  "learned",
  "flash",
  "back",
  "yarn",
  "fire",
  "descriptive",
  "well-off",
  "cheer",
  "nonchalant",
  "market",
  "scintillating",
  "reduce",
  "odd",
  "look",
  "suppose",
  "cheat",
  "cherries",
  "rebel",
  "bottle",
  "uttermost",
  "scold",
  "direction",
  "weary",
  "pretend",
  "transport",
  "lace",
  "lowly",
  "second-hand",
  "remarkable",
  "payment",
  "bashful",
  "furry",
  "want",
  "surprise",
  "tasteful",
  "touch",
  "soak",
  "childlike",
  "mask",
  "spiffy",
  "part",
  "thinkable",
  "knot",
  "invent",
  "lyrical",
  "tidy",
  "uncovered",
  "fertile",
  "school",
  "stitch",
  "bag",
  "attractive",
  "dress",
  "salt",
  "visitor",
  "gratis",
  "crack",
  "cushion",
  "nonstop",
  "fire",
  "wide",
  "start",
  "jeans",
  "floor",
  "legal",
  "brake",
  "paint",
  "serve",
  "jobless",
  "questionable",
  "bloody",
  "important",
  "fearless",
  "wise",
  "open",
  "feeling",
  "pale",
  "produce",
  "pin",
  "😆",
  "🙎🏼",
  "👼🏾",
  "👇🏾",
  "🎼",
  "🏍",
  "👧🏾",
  "👷🏼‍♀",
  "👍🏽",
  "🇺🇦",
  "🇰",
  "🇮🇱",
  "🇬🇾",
  "🚖",
  "🎈",
  "🎩",
  "😥",
  "🐌",
  "🇱🇦",
  "🚵‍♀",
  "✝",
  "🍅",
  "💌",
  "🏊🏾‍♀",
  "👱🏻",
  "😂",
  "✋🏻",
  "👱‍♀",
  "🎪",
  "📠",
  "🚭",
  "👶🏻",
  "👴",
  "🐎",
  "🇲🇳",
  "✂",
  "🕕",
  "💓",
  "💟",
  "🚆",
  "♐",
  "🇰🇮",
  "🏋🏻",
  "↪",
  "✔",
  "☀",
  "👏🏻",
  "⛹🏾‍♀",
  "🆘",
  "🏥",
  "🇸🇰",
  "🇷🇸",
  "👻",
  "✋🏿",
  "🔍",
  "🛍",
  "↗",
  "💲",
  "⚫",
  "🍉",
  "👩‍👩‍👦‍👦",
  "💁🏼",
  "🍎",
  "➕",
  "🖖🏽",
  "💎",
  "🇻🇬",
  "👨‍👧‍👧",
  "💪🏼",
  "🏁",
  "🍬",
  "😈",
  "💇🏼‍♂",
  "🇩🇿",
  "🍍",
  "📂",
  "☑",
  "😤",
  "🚣🏽‍♀",
  "♣️",
  "💱",
  "🔲",
  "💳",
  "🇨🇭",
  "🌭",
  "👨‍👨‍👧‍👧",
  "👞",
  "👷🏻",
  "🇹🇫",
  "📿",
  "🍟",
  "🗺",
  "🙅‍♂",
  "🇲🇶",
  "⛈",
  "🇸🇨",
  "🈷",
  "🙏",
  "🇷",
  "📄",
  "Birds of a feather rock together",
  "I brought you into this world so I can take you out!",
  "A man’s got to do what a man’s got to do.",
  "The road to hell is paved with good intentions.",
  "Don't forget to wash behind your ears.",
  "Would you jump off a bridge if all your friends were doing it?",
  "The squeaking wheel gets the oil.",
  "You talkin' to me?",
  "Money is the root of all evil.",
  "You dirty old man!",
  "Why can't you trust the king of the jungle? Because he's always lion.",
  "What do you call a sleeping bull? A bull-dozer.",
  "Which U.S. state has the smallest soft drinks? Minne-sota",
  "Why did the stadium get hot after the game? All the fans left.",
  "How does a rancher keep track of his cattle? With a cow-culator.",
  "What did the baby corn say to the mama corn? Where's pop corn?",
  "Why do cows wear bells? Because their horns don't work.",
  "What comes down, but never comes up? Rain.",
  "What's the best time to go to the dentist? Tooth hurty.",
  "Why did the barber win the race? He knew a shortcut.",
  "apples to oranges",
  "put the cart before the horse",
  "The customer is always right",
  "Go with the flow",
  "benchmark",
  "seamless integration",
  "Paradigm Shift",
  "Maximize leverage",
  "Let's take this off-line",
  "Drop the ball",
  "Know ye not that they which run in a race run all, but one receiveth the prize? So run, that ye may obtain",
  "let's be proactive here people",
  "circle back",
  "agile development",
  "random"
];

const randomWord = () =>
  randomWords[Math.floor(Math.random() * randomWords.length)];

export default randomWord;
