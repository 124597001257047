// @flow
import { WITHDRAW_FUNDS_SUCCESS } from "../actions/action-types";

export const fundsReducer = (state = 0, action) => {
  switch (action.type) {
    case WITHDRAW_FUNDS_SUCCESS:
      // create set lemma id to loading
      return action.payload.funds;
    default:
  }
  return state;
};
