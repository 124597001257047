// @flow
import { put, call, select, all } from "redux-saga/effects";

import { isEmpty, sortBy, reduce, filter } from "lodash";

import web3 from "../services/web3";
import {
  bytesToString,
  parseUInt256,
  getBlockTimestamp,
  compareIds
} from "../services/utils";
import {
  SALE_CONTRACT,
  getContractDetails
} from "../services/contracts/details";

import {
  lemmaData,
  saleAuctionData,
  releaseData,
  withdrawFundsSuccess,
  releasePageLoad
} from "../actions/actions";
import { getReleaseBids, getLemmaOfferOwner } from "../selectors/selectors";

import { requestSaleAuctionData } from "./lemma-sagas";
import { fetchCurrentReleaseId } from "./release-sagas";

export const successHandlers = {
  // account => bidder, lemma, value => price, lemmaBytes => id
  *makeLemmaBid({ account, value, params }) {
    const [lemmaBytes] = params;
    const id = web3.utils.toBN(web3.utils.soliditySha3(lemmaBytes)).toString();

    // update lemma bid value
    yield put(lemmaData.success(id, { bidValue: value, bidOwner: account }));

    // get current release id
    const releaseId = yield call(fetchCurrentReleaseId);

    if (!releaseId) return;

    const lemma = bytesToString(lemmaBytes);

    const bid = {
      id,
      price: value,
      bidder: account,
      lemma
    };

    // get release state
    // re-calculate bids by adding a new bid, removing bid loss
    const currentBids = yield select(getReleaseBids, releaseId);

    // if first bid just add it
    if (isEmpty(currentBids)) {
      yield put(releaseData.success(releaseId, { bids: [bid] }));
      return;
    }

    // else
    const validCurrentBids = filter(
      currentBids,
      oldBid => oldBid.lemma !== lemma
    );

    // sort bids in descending order
    const sortedBids = sortBy(
      [bid, ...validCurrentBids],
      ({ price: sortByPrice }) => -sortByPrice
    ).slice(0, 25);

    yield put(
      releaseData.success(releaseId, {
        bids: sortedBids
      })
    );
  },
  // lemmaBytes => id, account => owner, select release Id
  *composeLemma({ account, params }) {
    const [lemmaBytes] = params;

    const id = web3.utils.toBN(web3.utils.soliditySha3(lemmaBytes)).toString();

    // get current release id
    const releaseId = yield call(fetchCurrentReleaseId);

    if (!releaseId) return;

    yield put(
      lemmaData.success(id, { releaseId, owner: account, category: 1 })
    );
  },
  // id => id, definition => definition
  *defineLemma({ params }) {
    const [id, definition] = params;

    // and save it
    yield put(lemmaData.success(id, { definition }));
  },
  // account => seller, startingPrice, endingPrice, duration, startedAt = now, live => true
  *createSaleAuction({ account, params }) {
    const [id, startingPrice, endingPrice, duration] = params;

    const startedAt = yield call(getBlockTimestamp);

    const data = {
      seller: account,
      startingPrice,
      endingPrice,
      duration,
      startedAt,
      live: true
    };

    yield put(saleAuctionData.success({ id, data }));
  },
  *bidOnSaleAuction({ params }) {
    const [id] = params;

    // switch the owner to the sale contract
    const { address } = yield call(getContractDetails, SALE_CONTRACT);

    const data = {
      live: false
    };

    yield all([
      put(lemmaData.success(id, { owner: address })),
      put(
        saleAuctionData.success({
          id,
          data
        })
      )
    ]);
  },
  // id, live => false
  *cancelAuction({ account, params }) {
    const [id] = params;

    const data = {
      seller: null,
      live: false
    };

    yield all([
      put(lemmaData.success(id, { owner: account })),
      put(
        saleAuctionData.success({
          id,
          data
        })
      )
    ]);
  },
  // setCompositionFee
  // set new composition fee
  *setCompositionFee({ params }) {
    // params = [id, value]
    const [id, value] = params;

    yield put(
      lemmaData.success(id, { id, compositionFee: parseUInt256(value) })
    );
  },
  // withdrawFunds
  // set funds to 0
  *withdrawFunds() {
    yield put(
      withdrawFundsSuccess({
        funds: 0
      })
    );
  },

  // makeOffer
  // get tokenId and update offerValue and offerOwner
  // account => offerOwner, value => offerValue, id
  *makeOffer({ account, params, value }) {
    const [id] = params;

    const offer = {
      id,
      offerValue: value,
      offerOwner: account
    };

    // update lemma bid value
    yield put(lemmaData.success(id, offer));
  },

  // cancelOffer
  // get tokenId and zero offer value and owner
  // id  => id
  *cancelOffer({ params }) {
    const [id] = params;

    // clear lemma offer value
    yield put(lemmaData.success(id, { offerOwner: null, offerValue: 0 }));
  },

  // acceptOffer
  // get tokenId and change owner to bidder and zero offer value and owner
  // id, owner => select(offerOwner)
  *acceptOffer({ params }) {
    const [id] = params;

    const bidder = yield select(getLemmaOfferOwner, id);

    const data = {
      owner: bidder,
      offerValue: 0,
      offerOwner: null
    };

    // update lemma bid value
    yield put(lemmaData.success(id, data));
  }
};

export const confirmationHandlers = {
  *makeLemmaBid(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { BidSuccess, BidLoss }
    } = receipt;

    const {
      returnValues: { lemmaId, price, lemmaReleaseId: releaseId, bidder, lemma }
    } = BidSuccess;

    const {
      returnValues: { lemmaId: lostBidId }
    } = BidLoss || { returnValues: { lemmaId: undefined } };

    // update release
    // also invent?
    const bid = {
      id: lemmaId,
      price: parseUInt256(price),
      bidder,
      lemma: bytesToString(lemma)
    };

    // update lemma bid value
    yield put(lemmaData.success(lemmaId, { bidValue: parseUInt256(price) }));

    // get release state
    // re-calculate bids by adding a new bid, removing bid loss
    const currentBids = yield select(getReleaseBids, releaseId);

    // if first bid just add it
    if (isEmpty(currentBids)) {
      yield put(releaseData.success(releaseId, { bids: [bid] }));
      return;
    }

    // else
    const validCurrentBids = reduce(
      currentBids,
      (bids = [], oldBid) => {
        // if same id do nothing because it must have been overriden
        if (compareIds(oldBid.id, lemmaId)) return bids;

        // if BidLoss existed, remove interval
        if (compareIds(oldBid.id, lostBidId)) return bids;

        // else keep the old bid
        return [...bids, oldBid];
      },
      []
    );

    // sort bids in descending order
    const sortedBids = sortBy(
      [...validCurrentBids, bid],
      ({ price: sortByPrice }) => -sortByPrice
    );

    yield put(
      releaseData.success(releaseId, {
        bids: sortedBids
      })
    );
  },
  *composeLemma(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { Invent }
    } = receipt;
    const {
      returnValues: { lemmaId, releaseId, inventor }
    } = Invent;

    yield put(
      lemmaData.success(lemmaId, { releaseId, owner: inventor, category: 1 })
    );
  },
  *defineLemma(receipt) {
    if (!receipt || !receipt.events) return;

    // get definition and id
    const {
      events: { Define }
    } = receipt;
    const {
      returnValues: { lemmaId, definition }
    } = Define;
    // and save it
    yield put(lemmaData.success(lemmaId, { definition }));
  },
  *createSaleAuction(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { AuctionCreated }
    } = receipt;
    const {
      returnValues: { tokenId }
    } = AuctionCreated;

    yield call(requestSaleAuctionData, tokenId);
  },
  *bidOnSaleAuction(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { AuctionSuccessful }
    } = receipt;

    const {
      returnValues: { tokenId, winner }
    } = AuctionSuccessful;

    yield all([
      put(lemmaData.success(tokenId, { owner: winner })),
      put(
        saleAuctionData.success({
          id: tokenId,
          data: {
            live: false
          }
        })
      )
    ]);
  },
  *cancelAuction(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { AuctionCancelled }
    } = receipt;
    const {
      returnValues: { tokenId }
    } = AuctionCancelled;

    yield put(
      saleAuctionData.success({
        id: tokenId,
        data: {
          live: false
        }
      })
    );
  },

  // makeOffer
  // get tokenId and update offerValue and offerOwner
  *makeOffer(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { OfferSuccess }
    } = receipt;

    const {
      returnValues: { lemmaId, price, bidder }
    } = OfferSuccess;

    const offer = {
      id: lemmaId,
      offerValue: parseUInt256(price),
      offerOwner: bidder
    };

    // update lemma bid value
    yield put(lemmaData.success(lemmaId, offer));
  },

  // cancelOffer
  // get tokenId and zero offer value and owner
  *cancelOffer(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { OfferCancel }
    } = receipt;

    const {
      returnValues: { lemmaId }
    } = OfferCancel;

    // update lemma bid value
    yield put(lemmaData.success(lemmaId, { offerOwner: null, offerValue: 0 }));
  },

  // acceptOffer
  // get tokenId and change owner to bidder and zero offer value and owner
  *acceptOffer(receipt) {
    if (!receipt || !receipt.events) return;

    const {
      events: { OfferAccept }
    } = receipt;

    const {
      returnValues: { lemmaId, bidder }
    } = OfferAccept;

    // update lemma bid value
    yield put(
      lemmaData.success(lemmaId, {
        owner: bidder,
        offerValue: 0,
        offerOwner: null
      })
    );
  },

  // releaesNextLemmas
  // Re-fetch release data
  *releaseNextLemmas() {
    yield put(releasePageLoad({}));
  }
};
// TODO
export const errorHandlers = {};
