import { get } from "lodash";

import { weiToEther } from "../services/utils";
// state selectors
// TODO: Refactor into smaller files

/** WEB3 AUTH SELECTORS * */

export const isWeb3Authenticating = state =>
  get(state, ["web3", "isLoading"], true);

export const isWeb3Authenticated = state =>
  Boolean(get(state, ["web3", "account"], false));

export const getWeb3Account = state => get(state, ["web3", "account"]);

export const getNetwork = state => get(state, ["web3", "network"]);

/** LEMMA SELECTORS * */
export const isLoadingLemmaData = (state, id) => {
  const loading = get(state, ["lemmas", id, "isLoading"]);
  return loading || loading === undefined;
};

// TODO: Make a seperate context reducer
export const getPageLemmaId = state => get(state, ["lemmas", "context", "id"]);

export const getLemma = (state, id) => get(state, ["lemmas", id, "lemma"]);

export const getLemmaOwner = (state, id) => get(state, ["lemmas", id, "owner"]);

export const getLemmaReleaseId = (state, id) =>
  get(state, ["lemmas", id, "releaseId"]);

export const getLemmaCategory = (state, id) => {
  const categoryTypes = [
    "undefined",
    "defined",
    "illegal",
    "public",
    "whitespace",
    "special"
  ];
  const category = get(state, ["lemmas", id, "category"], 0);
  const readableCategory = categoryTypes[category];
  return readableCategory;
};

export const getLemmaPronunciation = (state, id) =>
  get(state, ["lemmas", id, "pronunciation"]);

export const getLemmaPartOfSpeech = (state, id) =>
  get(state, ["lemmas", id, "partOfSpeech"]);

export const getLemmaDefinition = (state, id) =>
  get(state, ["lemmas", id, "definition"]);

export const getLemmaSentence = (state, id) =>
  get(state, ["lemmas", id, "sentence"]);

// get composition fee (default is handled in contract)
export const getLemmaCompositionFee = (state, id) =>
  get(state, ["lemmas", id, "compositionFee"]);

export const getLemmaOfferOwner = (state, id) =>
  get(state, ["lemmas", id, "offerOwner"]);

export const getLemmaOfferValue = (state, id) =>
  get(state, ["lemmas", id, "offerValue"], 0);

// is a lemma defined? default to false
export const isLemmaDefined = (state, id) =>
  get(state, ["lemmas", id, "category"], 0) > 0;

// is lemma owners
export const isLemmaOwner = (state, id) => {
  const owner = getLemmaOwner(state, id);
  const currentAccount = getWeb3Account(state);

  return Boolean(currentAccount) && owner === currentAccount;
};

export const isLemmaOfferOwner = (state, id) => {
  const owner = getLemmaOfferOwner(state, id);
  const currentAccount = getWeb3Account(state);

  return Boolean(currentAccount) && owner === currentAccount;
};

export const getLemmaBidValue = (state, id) =>
  get(state, ["lemmas", id, "bidValue"], 0);

export const getLemmaBidOwner = (state, id) =>
  get(state, ["lemmas", id, "bidOwner"]);

export const isLemmaInCurrentRelease = (state, id) =>
  get(state, ["lemmas", id, "bidValue"], 0) > 0;

export const isBaseLemma = (state, id) => get(state, ["lemmas", id, "isBase"]);

export const isLemmaComposable = (state, id) =>
  get(state, ["lemmas", id, "isComposable"]);

export const isLemmaLicensable = (state, id) =>
  get(state, ["lemmas", id, "isLicensable"]);

export const getLemmaCanCompose = (state, id) =>
  get(state, ["lemmas", id, "canCompose"]);

export const getLemmaCompositionBreakpoints = (state, id) =>
  get(state, ["lemmas", id, "breakpoints"]);

export const getLemmaCompositionCost = (state, id) =>
  get(state, ["lemmas", id, "compositionCost"]);

export const hasCachedLemmaData = (state, id) => {
  const category = getLemmaCategory(state, id);

  if (category > 0) return true;

  const isBase = isBaseLemma(state, id);
  const isComposable = isLemmaComposable(state, id);

  // for undefined lemmmas (category 0), we check for base and composable
  return isBase || isComposable;
};

/** AUCTION SELECTORS * */
export const getAuctionSeller = (state, auctionType, id) =>
  get(state, ["auctions", auctionType, id, "seller"]);

export const getAuctionStartingPrice = (state, auctionType, id) =>
  parseFloat(
    weiToEther(get(state, ["auctions", auctionType, id, "startingPrice"], "0"))
  );

export const getAuctionEndingPrice = (state, auctionType, id) =>
  parseFloat(
    weiToEther(get(state, ["auctions", auctionType, id, "endingPrice"], "0"))
  );

export const getAuctionDuration = (state, auctionType, id) =>
  get(state, ["auctions", auctionType, id, "duration"]);

export const getAuctionStartTime = (state, auctionType, id) =>
  get(state, ["auctions", auctionType, id, "startedAt"]);

// auction is live is startedAt field is not 0 and its still within the duration defined
export const isAuctionLive = (state, auctionType, id) =>
  get(state, ["auctions", auctionType, id, "live"], false);

export const isAuctionSeller = (state, auctionType, id) => {
  const account = getWeb3Account(state);
  const seller = get(state, ["auctions", auctionType, id, "seller"]);

  return Boolean(account) && account === seller;
};

/** RELEASE SELECTORS * */

export const isLoadingReleaseData = (
  state,
  id // else requesting id data
) => get(state, ["releases", id, "isLoading"]);

export const getCurrentReleaseId = state =>
  get(state, ["releases", "current", "id"]);

export const getCurrentReleaseMinBid = state =>
  get(state, ["releases", "current", "minBid"], 0);

export const getCurrentReleaseTimeRemaining = state =>
  get(state, ["releases", "current", "timeRemaining"]);

export const getCurrentReleaseBlockSize = state =>
  get(state, ["releases", "current", "currentReleaseBlockSize"]);

export const getPageReleaseId = state => get(state, ["releases", "page"]);

export const getReleaseBids = (state, id) =>
  get(state, ["releases", id, "bids"]);

export const isCurrentReleaseOver = state =>
  getCurrentReleaseBlockSize(state) > 0 &&
  getCurrentReleaseTimeRemaining(state) === 0;

/** OTHER SELECTORS * */
export const getFunds = (state, id) => get(state, ["funds"], 0);
