import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  modalOpen,
  withdrawButtonLoad,
  withdrawButtonClick,
  withdrawFunds
} from "../../actions/actions";
import { getFunds } from "../../selectors/selectors";
import { weiToEther } from "../../services/utils";

import "./WithdrawButton.css";

type Props = {
  withdraw: () => void,
  funds: ?number,
  onLoad: () => void
};

const WithdrawButton = ({ funds, withdraw, onLoad }: Props) => {
  useEffect(() => {
    onLoad();
  }, []);

  if (!funds) return null;

  return (
    // do not show the donation button on the homepage
    <button
      className="withdraw-button"
      onClick={() => withdraw(funds)}
      type="button"
    >
      <span className="withdraw-button-text">withdraw earnings </span>
    </button>
  );
};

const mapStateToProps = state => ({
  funds: getFunds(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(withdrawButtonLoad()),
  withdraw: amount => {
    dispatch(withdrawButtonClick());
    dispatch(
      modalOpen({
        name: "ComfirmationModal",
        data: {
          title: `withdraw ${weiToEther(amount)}`,
          handleSubmit: () => dispatch(withdrawFunds())
        }
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawButton);
