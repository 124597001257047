import { eventChannel } from "redux-saga";
import { isEmpty } from "lodash";

import web3 from "../web3";
import { etherToWei } from "../utils";

/**
 * Send a transaction to the contract and call the given method
 * @param {Transaction object} contractMethod the contract method to be called
 * @param {Number|String|BN|BigNumber} value the value transferred for the
 * transaction in wei
 */
export const sendTransaction = async ({
  address,
  abi,
  method,
  params = [],
  from,
  value = 0
}) => {
  // NOTE: Caller must handle errors
  // ONLY SUPPORT window.web3 for transactions!
  const gasLimit = 7000000;
  const contract = new window.web3.eth.Contract(abi, address);

  // NOTE: ASSUME VALUE IS IN ETH
  const weiValue = etherToWei(parseFloat(value));

  // WEB3 accepts numbers as strings
  const newParams = params.map(p => (typeof p === "number" ? p.toString() : p));

  const gas = await contract.methods[method](...newParams).estimateGas({
    gas: gasLimit,
    from,
    value: weiValue
  });

  // if passed the gas limit, reject
  if (gas >= gasLimit) {
    throw new Error(`Method ${method} ran out of gas`);
  }

  const gasPrice = await window.web3.eth.getGasPrice();

  // return the event channel once a
  return eventChannel(emitter => {
    // check if there is a unsubscribe
    const transaction = contract.methods[method](...newParams).send({
      from,
      gas,
      gasPrice,
      value: weiValue
    });

    transaction
      .once("transactionHash", hash => {
        console.log("HASH", hash);
        emitter(hash);
      })
      .on("error", error => {
        console.error("Transaction error:", error);
        emitter(new Error(error));
      })
      .on("confirmation", (confirmationNumber, receipt) => {
        console.log("CONFIRMED", confirmationNumber, receipt);
        // For now, automatically confirm even though the transactions may get orphaned
        emitter(receipt);
      });

    // do nothing on unsubscribe
    return () => {};
  });
};

export const contractCall = ({ address, abi, method, params = [] }) => {
  const contract = new web3.eth.Contract(abi, address);

  return contract.methods[method](...params).call();
};

export const getPastEvents = ({
  address,
  abi,
  event,
  filter,
  fromBlock = 0,
  toBlock = "latest"
}) =>
  new Promise((resolve, reject) => {
    const contract = new web3.eth.Contract(abi, address);

    return contract
      .getPastEvents(event, {
        filter,
        fromBlock,
        toBlock
      })
      .then(data => {
        // check for no events
        if (isEmpty(data)) {
          return resolve();
        }

        return resolve(data);
      })
      .catch(error => {
        console.error("Error getting past events", error);
        return reject();
      });
  });
