// @flow
import mixpanel from "mixpanel-browser";

export const TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

// initialize on file load
const isInitialized = new Promise(resolve => {
  // development token
  mixpanel.init(TOKEN, {
    loaded: () => {
      resolve();
    }
  });
});

// automatically track page
const getPage = (pathname: string) => {
  if (pathname.includes("manifesto")) {
    return "Manifesto";
  }
  if (pathname.includes("tips")) {
    return "User Guide";
  }
  if (pathname.includes("lemma-id")) {
    return "Lemma Id";
  }
  if (pathname.includes("lemma")) {
    return "Lemma";
  }
  if (pathname.includes("release")) {
    return "Release";
  }

  return "Home";
};

let previousEvent = "None";

export const track = async (event: string, properties: Object = {}) => {
  const finalProperties = {
    ...properties,
    page: getPage(global.location.pathname),
    previous: previousEvent
  };

  // save the previous event
  previousEvent = event;

  try {
    // wait for initialization to finish
    await isInitialized;

    if (mixpanel && mixpanel.track) {
      mixpanel.track(event, finalProperties);
    }
  } catch (error) {
    // do nothing
  }
};
