// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import * as selectors from "../../selectors/selectors";
import { bidOnSaleAuction, modalClose } from "../../actions/actions";
import { getBlockTimestamp } from "../../services/utils";

type Props = {
  lemma: string,
  show: boolean,
  handleClose: () => void,
  handleSubmit: number => void,
  startingPrice: number,
  endingPrice: number,
  duration: number,
  startedAt: number
};

type State = {
  value: number,
  intervalId: ?IntervalID
};

const Transition = props => <Slide direction="up" {...props} />;

class BuyModal extends Component<Props, State> {
  state = {
    value: 0,
    intervalId: null
  };

  componentDidMount() {
    // update price
    this.updatePrice();

    // start interval to continue to update price
    const intervalId = setInterval(this.updatePrice, 15000);

    this.setState({ intervalId });
  }

  componentWillUnmount() {
    // clear interval
    const { intervalId } = this.state;
    if (intervalId) clearInterval(intervalId);
  }

  updatePrice = async () => {
    const { startingPrice, endingPrice, duration, startedAt } = this.props;

    // rate of change in seconds
    const rateOfChange = (startingPrice - endingPrice) / duration;

    // time elapsed in seconds
    const now = await getBlockTimestamp();

    // if we can't get the current timestamp, do nothing
    if (!now) return;

    const timeElapsed = now - startedAt;

    const value = startingPrice - timeElapsed * rateOfChange;

    // 5% buffer on increasing auctions (rate of change is negative)
    const bufferedValue = rateOfChange >= 0 ? value : value * 1.05;

    const roundedValue = parseFloat(bufferedValue.toFixed(8));

    this.setState({
      value: roundedValue
    });
  };

  render() {
    const { value } = this.state;

    const {
      show,
      handleClose,
      handleSubmit,
      lemma,
      startingPrice,
      endingPrice
    } = this.props;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="md"
        TransitionComponent={Transition}
        aria-labelledby="submit-buy-dialog-title"
        aria-describedby="submit-buy-dialog-actions"
      >
        <DialogTitle id="submit-buy-dialog-title">
          purchase <strong>{lemma}</strong>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="buy-value"
            label="starting price (eth)"
            value={startingPrice}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            disabled
            margin="normal"
          />
          <TextField
            id="buy-value"
            label="ending price (eth)"
            value={endingPrice}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            disabled
            margin="normal"
          />
          <TextField
            id="buy-value"
            label="current price (eth)"
            value={value}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            disabled
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="large">
            cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(value);
              handleClose();
            }}
            color="primary"
            autoFocus
            size="large"
          >
            buy
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  startingPrice: selectors.getAuctionStartingPrice(state, "sale", ownProps.id),
  endingPrice: selectors.getAuctionEndingPrice(state, "sale", ownProps.id),
  duration: selectors.getAuctionDuration(state, "sale", ownProps.id),
  startedAt: selectors.getAuctionStartTime(state, "sale", ownProps.id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSubmit: value => dispatch(bidOnSaleAuction({ id: ownProps.id, value })),
  handleClose: () => dispatch(modalClose("BuyModal"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyModal);
