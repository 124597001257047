// import web 3
import { delay } from "redux-saga";
import {
  throttle,
  put,
  call,
  all,
  take,
  takeEvery,
  race
} from "redux-saga/effects";
import { web3Authentication } from "../actions/actions";
import { WEB3_INITIALIZE } from "../actions/action-types";
import { getNetworkName, hasWeb3Provider } from "../services/utils";

import web3 from "../services/web3";

const Web3 = require("web3");

/** *************************************************************************** */
/** *************************** Subroutines *********************************** */
/** *************************************************************************** */
let hasSetMetaMask = false;

const hasWeb3Account = accounts => Boolean(accounts && accounts.length > 0);

const KOVAN_NETWORK = "kovan";
const MAIN_NETWORK = "main";

// on app load, initialize web3
function* initializeWeb3() {
  console.log("initalizing web3...");
  // Modern dapp browsers...
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    try {
      console.log("Requesting to enable web3 for the lexiconomy");

      // Request account access if needed
      yield call(window.ethereum.enable);

      // validate credentials
      yield put(web3Authentication.request());
    } catch (error) {
      // User denied account access...
      console.log("Web3 access is required to use the lexiconomy");
      // fail
      yield put(web3Authentication.failure());
    }
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    window.web3 = new Web3(Web3.givenProvider);
    // validate the credentials
    yield put(web3Authentication.request());
  }
  // Non-dapp browsers...
  else {
    console.log(
      "Non-Ethereum browser detected. You should consider trying MetaMask!"
    );
    // fail
    yield put(web3Authentication.failure());
  }
}

// continually validate web3 credentials
function* validateWeb3Credentials() {
  // check for a web3 provider
  if (!(yield call(hasWeb3Provider))) {
    // no provider so fail immediately
    yield put(web3Authentication.failure());

    return;
  }

  // check the user is logged into an account
  const accounts = yield call(window.web3.eth.getAccounts);

  if (yield call(hasWeb3Account, accounts)) {
    // get the network from MetaMask (timeout after 5000 seconds )
    const [network, timedOut] = yield race([
      call(getNetworkName),
      call(delay, 5000)
    ]);

    if (timedOut) {
      // can't connect to network, web3 will not respond
      yield put(web3Authentication.failure());
      return;
    }

    // check for an invalid network
    if (network !== KOVAN_NETWORK && network !== MAIN_NETWORK) {
      // lexiconomy contract only exists on the kovan and main network
      yield put(web3Authentication.failure({ network }));
      return;
    }

    // put a success
    yield put(web3Authentication.success({ account: accounts[0], network }));

    if (!hasSetMetaMask) {
      // update the shared web3 object to use the browser provider
      web3.setProvider(Web3.givenProvider || window.ethereum);

      hasSetMetaMask = true;
    }

    return;
  }

  // fail
  yield put(web3Authentication.failure());
}

function* validateWeb3CredentialsFlow() {
  // wait for initialization
  yield take(WEB3_INITIALIZE);

  // then keep trying to validate
  yield throttle(1000 * 3, "*", validateWeb3Credentials);
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

// Refresh web3 auth credentials every 3 seconds
export function* watchWeb3Authentication() {
  yield all([
    takeEvery(WEB3_INITIALIZE, initializeWeb3),
    call(validateWeb3CredentialsFlow)
  ]);
}
