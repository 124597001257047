import { merge } from "lodash";
import { LEMMA_PAGE_LOAD, LEMMA_DATA } from "../actions/action-types";

const INITIAL_STATE = {};

export const lemmasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEMMA_PAGE_LOAD:
      // save the lemma and id in context
      return merge({}, state, {
        context: {
          id: action.payload.id,
          lemma: action.payload.lemma
        }
      });
    case LEMMA_DATA.REQUEST:
      // create set lemma id to loading
      return merge({}, state, { [action.payload.id]: { isLoading: true } });
    case LEMMA_DATA.SUCCESS:
      // create set lemma data
      return merge({}, state, {
        [action.payload.id]: { isLoading: false, ...action.payload.data }
      });
    case LEMMA_DATA.FAILURE:
      // stop loading
      return merge({}, state, { [action.payload.id]: { isLoading: false } });
    default:
  }
  return state;
};
