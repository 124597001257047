// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { differenceInSeconds, addMinutes } from "date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import AuctionDurationPicker from "../../components/common/AuctionDurationPicker";

import { createSaleAuction, modalClose } from "../../actions/actions";
import { etherToWei } from "../../services/utils";

type Props = {
  lemma: string,
  show: boolean,
  handleClose: () => void,
  createAuctionRequest: (number, number, number) => void
};

type State = {
  startingPrice: number,
  endingPrice: number,
  duration: number,
  endDate: ?Date
};

const fifteenMinutes = addMinutes(new Date(), 15);

// 3 minutes
const MINIMUM_SECONDS_DURATION = 60 * 3;

const Transition = props => <Slide direction="up" {...props} />;

class SellModal extends Component<Props, State> {
  state = {
    startingPrice: 2,
    endingPrice: 1,
    endDate: fifteenMinutes,
    duration: 15 * 60 * 60
  };

  static defaultProps = {
    initialValue: 0
  };

  isValidPrice = (price: number) => price > 0;

  isValidDate = (duration: number) => duration > MINIMUM_SECONDS_DURATION;

  isValidAuction = () => {
    const { isValidPrice, isValidDate } = this;
    const { startingPrice, endingPrice, duration } = this.state;

    return (
      isValidPrice(startingPrice) &&
      isValidPrice(endingPrice) &&
      isValidDate(duration)
    );
  };

  handleInput = (value, field) =>
    this.setState({
      [field]: value
    });

  handleDateChange = (endDate: Date) => {
    const duration = differenceInSeconds(endDate, new Date());

    this.setState({ endDate, duration });
  };

  handleSubmit = () => {
    const { startingPrice, endingPrice, duration } = this.state;
    const { handleClose, createAuctionRequest } = this.props;

    createAuctionRequest(startingPrice, endingPrice, duration);
    handleClose();
  };

  render() {
    const { startingPrice, endingPrice, duration, endDate } = this.state;

    const { show, handleClose, lemma } = this.props;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="submit-sale-auction-dialog-title"
        aria-describedby="submit-sale-auction-dialog-actions"
      >
        <DialogTitle id="submit-sale-auction-dialog-title">
          start a sale auction for <strong>{lemma}</strong>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="sale-auction-starting-price"
            label="starting price (eth)"
            value={startingPrice}
            onChange={e => this.handleInput(e.target.value, "startingPrice")}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            error={!this.isValidPrice(startingPrice)}
          />
          <TextField
            id="sale-auction-ending-price"
            label="ending price (eth)"
            value={endingPrice}
            onChange={e => this.handleInput(e.target.value, "endingPrice")}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            error={!this.isValidPrice(endingPrice)}
          />
          <AuctionDurationPicker
            selected={endDate}
            error={!this.isValidDate(duration)}
            handleChange={this.handleDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={handleClose} color="secondary">
            cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            autoFocus
            size="large"
            disabled={!this.isValidAuction()}
          >
            start
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createAuctionRequest: (startingPrice, endingPrice, duration) =>
    dispatch(
      createSaleAuction({
        id: ownProps.id,
        startingPrice: etherToWei(startingPrice),
        endingPrice: etherToWei(endingPrice),
        duration
      })
    ),
  handleClose: () => dispatch(modalClose("SellModal"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellModal);
