import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Home from "./Home";
import LemmaPage from "./LemmaPage";
import ReleasePage from "./ReleasePage";
import ManifestoPage from "./ManifestoPage";
import TipsPage from "./TipsPage";

// Routes defines our apps main routes
const Routes = () => (
  <Switch>
    {/* // home page */}
    <Route exact path="/" component={Home} />

    {/* // diplay our manifesto */}
    <Route path="/manifesto" component={ManifestoPage} />

    <Route path="/tips" component={TipsPage} />

    {/* all things lemma (route by lemma or by lemma Id) */}
    <Route path="/lemma/:lemma" component={LemmaPage} />
    <Route path="/lemma-id/:id" component={LemmaPage} />

    {/* current release */}
    <Route path="/release/current" component={ReleasePage} />
    {/* lemma release information by release id */}
    <Route path="/release/:id" component={ReleasePage} />

    {/* TODO: for future iterations on the site
      // more information about the
      lexiconomy
      <Route path="/information" />
      // user account information from metamask address
      <Route path="/account" />
      // ?? sounds cool though
      <Route path="/explore" />
      // ?? also sounds cool
    <Route path="/marketplace" /> */}
    {/* if the url did not match the above redirect to home */}
    <Redirect to="/" />
  </Switch>
);

export default Routes;
