export const symbols = [
  "~",
  "`",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "-",
  "_",
  "=",
  "+",
  "[",
  "]",
  "{",
  "}",
  "\\",
  "|",
  ";",
  ":",
  "'",
  '"',
  "<",
  ">",
  "/",
  "¡",
  "?",
  "≠",
  "–",
  "¢",
  "¥",
  "₩",
  "元",
  "£",
  "₽",
  "₹",
  ",",
  ".",
  "…"
];

export const vowels = ["a", "e", "i", "o", "u", "y"];

export const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const words = [
  "lexiconomy",
  "the",
  "be",
  "was",
  "are",
  "is",
  "were",
  "to",
  "of",
  "and",
  "in",
  "that",
  "have",
  "it",
  "for",
  "not",
  "on",
  "with",
  "he",
  "as",
  "you",
  "do",
  "at",
  "this",
  "but",
  "his",
  "by",
  "from",
  "they",
  "we",
  "say",
  "her",
  "she",
  "or",
  "will",
  "an",
  "my",
  "one",
  "all",
  "would",
  "there",
  "their",
  "what",
  "so",
  "up",
  "out",
  "if",
  "about",
  "who",
  "get",
  "which",
  "go",
  "when",
  "me",
  "make",
  "can",
  "like",
  "no",
  "just",
  "him",
  "know",
  "take",
  "into",
  "year",
  "your",
  "some",
  "could",
  "them",
  "see",
  "other",
  "than",
  "then",
  "now",
  "look",
  "only",
  "its",
  "over",
  "think",
  "also",
  "back",
  "after",
  "use",
  "how",
  "our",
  "well",
  "way",
  "even",
  "new",
  "want",
  "because",
  "any",
  "these",
  "give",
  "day",
  "most",
  "us"
];

export const suffixes = ["'s"];
