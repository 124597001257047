import { createAction, SALE_AUCTION_DATA } from "./action-types";

/** SALE AUCTION ACTIONS * */
export const saleAuctionData = {
  request: ({ id }) => createAction(SALE_AUCTION_DATA.REQUEST, { id }),
  success: ({ id, data }) =>
    createAction(SALE_AUCTION_DATA.SUCCESS, { id, data }),
  failure: ({ id, text }) =>
    createAction(SALE_AUCTION_DATA.FAILURE, {
      id,
      message: {
        kind: "error",
        text
      }
    })
};
