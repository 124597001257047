// @flow
import React from "react";

import withPageView from "../../analytics/PageView";
import { defaultNetwork } from "../../services/utils";

import "./InvalidNetwork.css";

// Lemma Page Container Component
const InvalidNetwork = () => (
  <div className="invalid-network-container">
    <h1 className="invalid-network-title">
      Invalid network{" "}
      <span role="img" aria-label="stop">
        🛑
      </span>
    </h1>
    <div className="invalid-network-description">
      to explore the lexiconomy switch to the {defaultNetwork} ethereum network.
    </div>
  </div>
);

export default withPageView("Invalid Network")(InvalidNetwork);
