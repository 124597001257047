// @flow
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Search from "react-feather/dist/icons/search";
import Menu from "react-feather/dist/icons/menu";

import classnames from "classnames";

import { commonHeaderClick } from "../../actions/actions";

import SearchBar from "../../containers/search-bar/SearchBar";

import "./NavBar.css";

type Props = {
  location: {
    pathname: string
  },
  track: string => void
};

type State = {
  showSearch: boolean,
  showMobileMenu: boolean
};

class NavBar extends React.Component<Props, State> {
  state = {
    showSearch: false,
    showMobileMenu: false
  };

  showSearchBar = () => this.setState({ showSearch: true });

  hideSearchBar = () => this.setState({ showSearch: false });

  handleMobileMenuClick = () => {
    this.setState(state => ({
      showMobileMenu: !state.showMobileMenu
    }));
  };

  render() {
    const { track } = this.props;
    const { showSearch, showMobileMenu } = this.state;
    /* We need a mobile header at 780px */

    // hide the navbar if on the homepage
    const navClass = classnames("nav-bar font-space-mono", {
      "nav-bar-expanded": showMobileMenu
    });

    // show a search icon or the search bar
    const navSearch = (
      <div
        className="nav-search"
        role="button"
        tabIndex={0}
        onClick={this.showSearchBar}
        onKeyDown={this.showSearchBar}
        onMouseEnter={this.showSearchBar}
        onMouseLeave={this.hideSearchBar}
      >
        <Search className="nav-search-icon" />
        <SearchBar
          fullWidth
          containerClassName={classnames("nav-search-bar", {
            "search-visible": showSearch
          })}
          autoFocus
          placeholder="discover a word or phrase"
          onBlur={this.hideSearchBar}
        />
      </div>
    );

    const fullHeader = (
      <div className={navClass}>
        <div className="nav-bar-container">
          {/* Hide lexiconomy or replace with logo at certain width */}
          <Menu
            className="nav-mobile-menu-icon clickable"
            onClick={this.handleMobileMenuClick}
          />
          <nav className="nav-title">
            <Link onClick={() => track("lexiconomy")} to="/">
              lexiconomy
            </Link>
          </nav>
          {/* Keep these both until 780 */}
          {navSearch}

          <nav className="nav-spacer nav-item">
            <Link onClick={() => track("releases")} to="/release/current">
              releases
            </Link>
          </nav>
          <nav className="nav-item">
            <Link onClick={() => track("manifesto")} to="/manifesto">
              manifesto
            </Link>
          </nav>
          <nav className="nav-item">
            <Link onClick={() => track("guide")} to="/tips">
              guide
            </Link>
          </nav>
        </div>

        <nav className="nav-mobile-menu">
          <Link
            onClick={() => {
              this.handleMobileMenuClick();
              track("home");
            }}
            to="/"
          >
            home
          </Link>
          <Link
            onClick={() => {
              this.handleMobileMenuClick();
              track("releases");
            }}
            to="/release/current"
          >
            releases
          </Link>
          <Link
            onClick={() => {
              this.handleMobileMenuClick();
              track("manifesto");
            }}
            to="/manifesto"
          >
            manifesto
          </Link>
          <Link
            onClick={() => {
              this.handleMobileMenuClick();
              track("guide");
            }}
            to="/tips"
          >
            guide
          </Link>
        </nav>
      </div>
    );

    return <header>{fullHeader}</header>;
  }
}

const mapDispatchToProps = dispatch => ({
  track: name => dispatch(commonHeaderClick(name))
});

export default connect(
  null,
  mapDispatchToProps
)(NavBar);
