import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import "./Loading.css";

const Loading = () => (
  <div className="common-loading-page">
    <CircularProgress className="common-loading-circle" size={75} />
  </div>
);

export default Loading;
