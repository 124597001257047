/** MAP FROM METHOD TO USER MESSAGES * */

export const donate = {
  success: "Your donation was successful! Thank you 🤗",
  failure: "Your donation failed 😢"
};

export const makeLemmaBid = {
  success: "Your bid is a candidate for the next release 🎉",
  failure: "Your bid failed. Please try again."
};

export const createSaleAuction = {
  success: "Sale auction created!",
  failure: "Failed to start a sale auction. Please try again."
};

export const defineLemma = {
  success: "Definition success!",
  failure: "Failed to create a new definition. Please try again."
};
