// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import * as selectors from "../../selectors/selectors";

import {
  composeLemma,
  modalClose,
  lemmaComposeModalLoad
} from "../../actions/actions";

import { weiToEther } from "../../services/utils";

import "./Modal.css";

type Props = {
  lemma: string,
  show: boolean,
  canCompose: boolean,
  value: number,
  breakpoints: Array<number>,
  handleClose: () => void,
  handleSubmit: (Array<number>, number) => void,
  onLoad: () => void
};

const Transition = props => <Slide direction="up" {...props} />;

class ComposeModal extends Component<Props> {
  componentDidMount() {
    // check if user can compose
    this.props.onLoad();
  }

  render() {
    const {
      show,
      handleClose,
      handleSubmit,
      canCompose,
      lemma,
      value,
      breakpoints
    } = this.props;

    // default to loading (canCompose === undefined)
    let content = (
      <div className="composeModalText">
        Checking to for a valid composition combination...
      </div>
    );

    if (canCompose === false) {
      content = (
        <div className="composeModalText">
          <div>No possible composition detected.</div>
          <div>
            There is no sequence of invented lemmas that compose{" "}
            <strong>{lemma}</strong>. Find out which lemmas aren't owned and
            invent them!
          </div>
          <div>
            Click{" "}
            <a className="compositonModalMoreInfo" href="/tips#composing">
              here
            </a>{" "}
            for more information on composition.
          </div>
        </div>
      );
    } else if (canCompose) {
      content = (
        <TextField
          id="compose-value"
          label="compostion cost (eth)"
          value={weiToEther(value)}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          disabled
          margin="normal"
          size="large"
        />
      );
    }

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="md"
        TransitionComponent={Transition}
        aria-labelledby="submit-compose-dialog-title"
        aria-describedby="submit-compose-dialog-actions"
      >
        <DialogTitle id="submit-compose-dialog-title">
          compose <strong>{lemma}</strong>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="large">
            cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(breakpoints, weiToEther(value));
              handleClose();
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={!canCompose}
          >
            invent
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { id }) => ({
  canCompose: selectors.getLemmaCanCompose(state, id),
  breakpoints: selectors.getLemmaCompositionBreakpoints(state, id),
  value: selectors.getLemmaCompositionCost(state, id)
});

const mapDispatchToProps = (dispatch, { id, lemma }) => ({
  handleSubmit: (breakpoints, value) =>
    dispatch(composeLemma({ lemma, breakpoints, value })),
  handleClose: () => dispatch(modalClose("ComposeModal")),
  onLoad: () => dispatch(lemmaComposeModalLoad({ id, lemma }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeModal);
