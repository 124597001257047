import React from "react";
import { connect } from "react-redux";

import { modalOpen } from "../../actions/actions";

import "./DonateButton.css";

type Props = {
  donate: () => void
};

const DonateButton = ({ donate }: Props) => (
  // do not show the donation button on the homepage
  <button className="donate-button" onClick={donate} type="button">
    <span className="donate-button-text">donate </span>
  </button>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  donate: () => dispatch(modalOpen({ name: "DonateModal" }))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonateButton);
