import React from "react";
import { reduce } from "lodash";

import web3 from "./web3";

export const isProduction = process.env.NODE_ENV === "production";

export const defaultNetwork = isProduction ? "main" : "kovan";

// TODO: Get Etherscan URL from Network name
export const getEtherscanURL = isProduction ? "https://etherscan.io" : "https://kovan.etherscan.io";

// TODO: Get Token ID from Network Name
export const LEX_TOKEN_ID = isProduction ? "0x05dde4609035e464f995d13221b5166080634f21" : "0x2ca26547225a73b982b94d743cde216a8002a23e";

const ETH_TO_WEI = 1000000000000000000;

export const displayAddress = address => address && address.slice(0, 8);

export const weiToEther = (amount = "0") => {
  const safeAmount = typeof amount === "number" ? String(amount) : amount;
  return web3.utils.fromWei(safeAmount, "ether");
};

export const parseUInt256 = value => {
  // will either be undefined, string or a BigNumber
  if (!value) return 0;

  if (typeof value === "string") parseInt(value, 10);

  const valueBN = web3.utils.toBN(value);

  // hacky! but seems to work for now...
  return parseInt(valueBN.toString(), 10);
};

export const displayEtherValue = ether => (
  <span>
    <span className="uppercase">Ξ</span> {ether}
  </span>
);

export const displayWeiValueInEther = wei => (
  <span>
    <span className="uppercase">Ξ</span> {weiToEther(wei)}
  </span>
);

export const getNetworkName = () =>
  web3.eth.net.getNetworkType() || defaultNetwork;

export const hasWeb3Provider = () => Boolean(window.ethereum || window.web3);

export const hasValidNetwork = async () => {
  if (!hasWeb3Provider()) return true;

  const network = await getNetworkName();
  return network === defaultNetwork;
};

export const etherToWei = amount => ETH_TO_WEI * amount;

export const lemmaToBytes = lemma => web3.utils.utf8ToHex(lemma);

export const bytesToString = bytes => web3.utils.hexToUtf8(bytes);

export const getBlockNumber = async () => {
  try {
    const blockNumber = await web3.eth.getBlockNumber();
    return blockNumber;
  } catch (error) {
    return 0;
  }
};

export const getBlockTimestamp = async () => {
  try {
    const blockNumber = await web3.eth.getBlockNumber();
    const { timestamp } = await web3.eth.getBlock(blockNumber);
    return timestamp;
  } catch (error) {
    return 0;
  }
};

export const createLemmaId = lemma => {
  // get the byte value of the lemma
  const lemmaBytes = lemmaToBytes(lemma);

  // hash the bytes, cast it into uint256, and get the string value
  const lemmaId = web3.utils
    .toBN(web3.utils.soliditySha3(lemmaBytes))
    .toString();

  return lemmaId;
};

export const makeLemmaIdReadable = (lemma, len = 20) =>
  lemma ? `${lemma.slice(0, len)}...` : "";

export const getLemmaColor = lemma => {
  const colors = [
    "#fea3aa80", // salmon-ish
    "#f8b88b66", // light orange
    "#fef16073", // yellow
    "#baed9173", // green
    "#b2cefe80", // purple blue
    "#f2a2e84d", // pink
    "#b0f4e880", // light blue green
    "#a7f69a66", // green
    "#f4a1a180", // a pink
    "#f6b6f180", // a pink
    "#edf29280", // a yellow
    "#6eb5ff73", // a green
    "#77dd7780", // a green
    "#ffcb0573" // orange
  ];

  const lemmaCode =
    lemma.length === 1
      ? lemma.codePointAt(0)
      : reduce(
          lemma,
          (sum = 0, char, index) => sum + char.codePointAt(0) * index,
          0
        );

  const colorPosition = lemmaCode % colors.length;

  return colors[colorPosition];
};

export const getGraphColor = lemma => {
  const lemmaColor = getLemmaColor(lemma);

  const nonBlue = ["#b2cefe80", "#6eb5ff73", "#b0f4e880"];

  if (nonBlue.includes(lemmaColor)) return "#FFa343";

  // else return blue
  return "#6eb5ff";
};

export const compareIds = (a, b) => {
  // convert to Big Number
  // is BigNumber
  if (typeof a === "object" && typeof b === "object") {
    return a.toString() === b.toString();
  }
  if (typeof a === "object" && typeof b === "string") {
    return a.toString() === b;
  }
  if (typeof a === "string" && typeof b === "object") {
    return a === b.toString();
  }

  return a === b;
};
