import { all, fork } from "redux-saga/effects";

import { trackActions } from "./analytics-sagas";
import { watchCommonActions } from "./common-sagas";
import { watchWeb3Authentication } from "./web3-auth-sagas";
import { watchMessages } from "./message-sagas";
import { watchLemmaPageLoad } from "./lemma-sagas";
import { watchReleasePageLoad } from "./release-sagas";
import watchTransactions from "./transaction-sagas";

// Export root sags
export default function* root() {
  yield all([
    fork(trackActions),
    fork(watchCommonActions),
    fork(watchWeb3Authentication),
    fork(watchMessages),
    fork(watchLemmaPageLoad),
    fork(watchReleasePageLoad),
    fork(watchTransactions)
  ]);
}
