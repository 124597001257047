// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import { defineLemma, modalClose } from "../../actions/actions";

type Props = {
  lemma: string,
  show: boolean,
  handleClose: () => void,
  handleSubmit: string => void
};

type State = {
  text: string
};

const Transition = props => <Slide direction="up" {...props} />;

class DefineModal extends Component<Props, State> {
  state = {
    text: ""
  };

  handleInput = event =>
    this.setState({
      text: event.target.value
    });

  render() {
    const { text } = this.state;

    const { show, handleClose, handleSubmit, lemma } = this.props;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="md"
        TransitionComponent={Transition}
        aria-labelledby="submit-define-dialog-title"
        aria-describedby="submit-define-dialog-actions"
      >
        <DialogTitle id="submit-define-dialog-title">
          define <strong>{lemma}</strong>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="define-value"
            label="enter a new definition"
            value={text}
            onChange={this.handleInput}
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            multiline
            rows={2}
            rowsMax={15}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="large">
            cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(text);
              handleClose();
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={!(text && text.trim())}
          >
            define
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSubmit: definition =>
    dispatch(defineLemma({ id: ownProps.id, definition })),
  handleClose: () => dispatch(modalClose("DefineModal"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefineModal);
