import {
  takeLatest,
  takeEvery,
  put,
  call,
  all,
  cancelled,
  select
} from "redux-saga/effects";
import { reduce, last } from "lodash";

import {
  LEMMA_PAGE_LOAD,
  LEMMA_COMPOSE_MODAL_LOAD
} from "../actions/action-types";

import { lemmaData, saleAuctionData } from "../actions/actions";

import { getWeb3Account } from "../selectors/selectors";

import {
  isBaseLemma,
  isComposableLemma,
  parseLemma,
  isValidComposition,
  findComposition,
  getLemmasToCompose,
  mapLemmasToByteBreakPoints
} from "../services/lemma-composition";

import {
  createLemmaId,
  getBlockTimestamp,
  parseUInt256
} from "../services/utils";

import {
  whitespaceRegex,
  separatorRegex
} from "../services/character-categorization";

import {
  LEXICONOMY_CONTRACT,
  SALE_CONTRACT,
  getContractDetails
} from "../services/contracts/details";

import {
  contractCall,
  getPastEvents
} from "../services/contracts/transactions";

const NO_ACCOUNT = "0x0000000000000000000000000000000000000000";

/** *************************************************************************** */
/** *************************** Subroutines *********************************** */
/** *************************************************************************** */

function* getLemmaContractData(id) {
  // always make sure we have the cotnract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return {};

  try {
    // get dictionary and owner data in parallel
    const {
      dictionary = {},
      owner,
      bid = {},
      compositionFee,
      offer = {}
    } = yield all({
      dictionary: call(contractCall, {
        abi,
        address,
        method: "dictionary",
        params: [id]
      }),
      owner: call(contractCall, {
        abi,
        address,
        method: "owners",
        params: [id]
      }),
      bid: call(contractCall, {
        abi,
        address,
        method: "bids",
        params: [id]
      }),
      compositionFee: call(contractCall, {
        abi,
        address,
        method: "getLemmaCompositionFee",
        params: [id, NO_ACCOUNT]
      }),
      offer: call(contractCall, {
        abi,
        address,
        method: "offers",
        params: [id]
      })
    });

    const { category, releaseId } = dictionary || {};
    const { value: bidValue, bidder: bidOwner } = bid || {};
    const { value: offerValue, bidder: offerOwner } = offer || {};

    return {
      category: parseUInt256(category),
      releaseId,
      owner,
      bidValue: parseUInt256(bidValue),
      bidOwner,
      compositionFee: parseUInt256(compositionFee),
      offerValue: parseUInt256(offerValue),
      offerOwner
    };
  } catch (error) {
    console.error(error);
    // if an error return nothing
    return {};
  }
}

function* requestLemmaDefinition(id) {
  // always make sure we have the cotnract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return;

  try {
    const data = yield call(getPastEvents, {
      abi,
      address,
      event: "Define",
      filter: {
        lemmaId: id
      }
    });

    if (!data) return;

    // return the last event
    const latestEvent = last(data);

    const { returnValues } = latestEvent;

    const { definition } = returnValues;

    // save the contract data
    yield put(lemmaData.success(id, { definition }));
  } catch (error) {
    // if an error do nothing
  }
}

function* isAuctionLive(startedAt, duration) {
  if (startedAt === 0) return false;

  const now = yield call(getBlockTimestamp);

  const timeElapsed = now - startedAt;

  return duration > timeElapsed;
}

// get sale auction data from the contract
function* getAuctionData(id, contract) {
  // always make sure we have the sale auction contract
  const { abi, address } = yield call(getContractDetails, contract);

  // if we do not have contract data return
  if (!abi || !address) return {};

  try {
    const {
      seller,
      startingPrice,
      endingPrice,
      duration,
      startedAt
    } = yield call(contractCall, {
      abi,
      address,
      method: "getAuction",
      params: [id]
    });

    const live = yield call(isAuctionLive, startedAt, duration);

    return {
      seller,
      startingPrice: parseUInt256(startingPrice),
      endingPrice: parseUInt256(endingPrice),
      duration: parseUInt256(duration),
      startedAt: parseUInt256(startedAt),
      live
    };
  } catch (error) {
    console.error(error);
    // if an error return nothing
    return {};
  }
}

// Request all auction data for a given lemma id
export function* requestSaleAuctionData(id) {
  // notify the request has started
  yield put(saleAuctionData.request({ id }));
  try {
    // get data
    const data = yield call(getAuctionData, id, SALE_CONTRACT);
    // put a success
    yield put(saleAuctionData.success({ id, data }));
  } catch (error) {
    // check for cancel
    if (!(yield cancelled())) {
      // put a failure
      yield put(
        saleAuctionData.failure({
          id,
          text: "Unable to get sale auction data."
        })
      );
    }
  }
}

// given an account and contract, can the account compose with the lemma
// lemma is composable if its defined
const accountCanComposeWith = (abi, address) => async lemma => {
  // if its only a whitespace character return true
  if (
    lemma.length === 1 &&
    (whitespaceRegex.test(lemma) || separatorRegex.test(lemma))
  )
    return true;

  // if it ends with whitespace return false
  const lastCharacter = last(lemma);
  if (whitespaceRegex.test(lastCharacter)) return false;

  // create the lemma id
  const id = createLemmaId(lemma);

  // check the contract for ownership or license
  const { category } = await contractCall({
    abi,
    address,
    method: "dictionary",
    params: [id]
  });

  const categoryNumber = parseUInt256(category);

  // defined, public, whitespace, or special lemmas can be composed
  return [1, 3, 4, 5].includes(categoryNumber);
};

function* createCanComposeWith() {
  // always make sure we have the cotnract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return null;

  // return the can composeWith async function loaded with the contract info
  return accountCanComposeWith(abi, address);
}

function* calculateCompositionCost(lemmasToCompose) {
  // always make sure we have the contract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return null;

  // get the current account
  const account = yield select(getWeb3Account);

  // if we don't have an account return
  if (!account) return null;

  // cache lemmas that already have been requested
  const cache = {};

  // reduce lemmas to the composition value
  const costPromise = reduce(
    lemmasToCompose,
    async (valuePromise, lemma) => {
      const value = await valuePromise;

      // first check cache
      if (cache[lemma]) return Promise.resolve(value + cache[lemma]);

      const id = createLemmaId(lemma);

      const feeString = await contractCall({
        abi,
        address,
        method: "getLemmaCompositionFee",
        params: [id, account]
      });

      const fee = parseUInt256(feeString);

      cache[lemma] = fee;

      return Promise.resolve(value + fee);
    },
    Promise.resolve(0)
  );

  const cost = yield costPromise;

  return cost;
}

function* fetchLemmaCompositionData({ payload: { id, lemma } }) {
  // parse the lemma into sub lemmas
  const subLemmas = yield call(parseLemma, lemma);

  // we cannot handle arrays over 1000
  if (subLemmas.length > 1000) {
    yield put(lemmaData.success(id, { canCompose: false }));
    return;
  }

  // load the canComposeWith function with the contract details
  const canComposeWith = yield call(createCanComposeWith);

  if (!canComposeWith) return;

  // get the breakpoints
  // TODO: Find multiple composition combos and show the cheapest!
  const subLemmaBreakpoints = yield call(
    findComposition(canComposeWith),
    subLemmas,
    0,
    1
  );

  // are the breakpoints valid
  const canCompose = yield call(isValidComposition, subLemmaBreakpoints);

  // save the result
  yield put(lemmaData.success(id, { canCompose }));

  // if its not we are done
  if (!canCompose) return;

  // get the byte breakpoints for the actual contract composition call
  const lemmasToCompose = yield call(
    getLemmasToCompose,
    subLemmas,
    subLemmaBreakpoints
  );

  const byteBreakpoints = yield call(
    mapLemmasToByteBreakPoints,
    lemmasToCompose
  );

  const compositionCost = yield call(calculateCompositionCost, lemmasToCompose);

  // save it
  yield put(
    lemmaData.success(id, { breakpoints: byteBreakpoints, compositionCost })
  );
}

function* loadLemmaData({ payload: { id, lemma } }) {
  // first fetch contract data
  const contractData = yield call(getLemmaContractData, id);

  // save the contract data
  yield put(lemmaData.success(id, { ...contractData }));

  const { category } = contractData;

  // if the lemma is defined fetch auction data and other external data
  if (category === 1) {
    // in parallel fetch all the data (calls will handle saves)
    yield all([
      call(requestLemmaDefinition, id),
      call(requestSaleAuctionData, id)
    ]);

    // done
    return;
  }

  // else, can the user bid or compose it
  // if we only have an id, we can't decide on bid vs compose
  if (!lemma) return;

  // check if its a base lemma
  const isBase = yield call(isBaseLemma, lemma);

  const isComposable = yield call(isComposableLemma, lemma);

  // save result
  yield put(lemmaData.success(id, { isBase, isComposable }));
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

// Fetches all lemma data on page load
export function* watchLemmaPageLoad() {
  yield all([
    yield takeEvery(LEMMA_PAGE_LOAD, loadLemmaData),
    yield takeLatest(LEMMA_COMPOSE_MODAL_LOAD, fetchLemmaCompositionData)
  ]);
}
