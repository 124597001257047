// @flow
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { map, isEmpty } from "lodash";

import * as selectors from "../../selectors/selectors";

import { releaseBlockClick, releaseNextLemmas } from "../../actions/actions";

import randomWord from "../../services/random-words";
import { getLemmaColor } from "../../services/utils";

import Card from "../../components/common/Card";
import Button from "../../components/common/Button";

import "../../components/release/Release.css";

type Bid = {
  id: string,
  lemma: string,
  bidder: string,
  price: number
};

type Props = {
  releaseId: number,
  bids: Array<Bid>,
  track: Function,
  isAuctionOver: boolean,
  endAuction: () => void
};

const ReleaseDataContainer = ({
  bids,
  track,
  releaseId,
  isAuctionOver,
  endAuction
}: Props) => {
  if (isEmpty(bids)) {
    const backgroundColor = getLemmaColor(String(releaseId));

    return (
      <div className="release-empty-state">
        This release hasn't started. To start the auction, place a bid for the
        first word!
        <Link
          className="release-empty-explore"
          to={`/lemma/${encodeURIComponent(encodeURIComponent(randomWord()))}`}
          style={{
            backgroundColor
          }}
        >
          explore
        </Link>
      </div>
    );
  }

  const releaseBlocks = map(bids, ({ id, bidder, lemma, price }) => (
    <Card
      key={id}
      title={lemma}
      price={price}
      address={bidder}
      onClick={() => track(lemma, price)}
      // double encode because react router decodes on push
      destination={`/lemma/${encodeURIComponent(encodeURIComponent(lemma))}`}
    />
  ));

  const endAuctionButton = (
    <div className="release-end-button">
      <Button onClick={endAuction}>end auction to release lemmas!</Button>
    </div>
  );

  return (
    <div>
      <div className="release-blocks">{releaseBlocks}</div>
      {isAuctionOver && endAuctionButton}
    </div>
  );
};

const mapStateToProps = state => {
  const releaseId = selectors.getPageReleaseId(state);
  const isAuctionOver = selectors.isCurrentReleaseOver(state);

  return {
    releaseId,
    bids: selectors.getReleaseBids(state, releaseId),
    isAuctionOver
  };
};

// sloppy :(
const mapDispatchToProps = dispatch => ({
  track: (lemma, price) => dispatch(releaseBlockClick({ lemma, price })),
  endAuction: () => dispatch(releaseNextLemmas())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseDataContainer);
