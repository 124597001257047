// @flow
import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { filter, times, every } from "lodash";

import * as selectors from "../../selectors/selectors";

import "./ReleasePagination.css";

type Props = {
  currentRelease: number,
  currentPage: number,
  showLeftArrow: boolean,
  showRightArrow: boolean,
  showDots: boolean,
  pages: Array<number>
};

const isNumber = n => typeof n === "number";

// Lemma Page Container Component
const ReleasePagination = ({
  currentRelease,
  currentPage,
  showLeftArrow,
  showRightArrow,
  showDots,
  pages
}: Props) => {
  if (!pages || !every(pages, isNumber)) return null;

  // given current page show n - 3 and n + 3
  const pageLinks = pages.map(i => (
    <NavLink
      key={i}
      to={`/release/${i}`}
      className="page"
      activeClassName="current-page"
      isActive={() => currentPage === i}
    >
      {i}
    </NavLink>
  ));

  const currentReleasePage = (
    <NavLink
      className="page"
      to={`${currentRelease}`}
      activeClassName="current-page"
      isActive={() => currentPage === currentRelease}
    >
      {currentRelease}
    </NavLink>
  );

  return (
    <div className="pagination-container">
      {showLeftArrow && (
        <Link className="arrow-left" to={`${currentPage - 1}`} />
      )}
      {pageLinks}
      {showDots && "..."}
      {currentReleasePage}
      {showRightArrow && (
        <Link className="arrow-right" to={`${currentPage + 1}`} />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  // get current page and current release (max page)
  const currentRelease = selectors.getCurrentReleaseId(state);
  const currentPage = selectors.getPageReleaseId(state);

  if (!currentRelease && !currentPage) return {};

  // this is ugly and confusing...I'm sorry
  const isOnCurrentPage = currentPage === currentRelease;

  // show 4 pages below, current, 4 pages above, current release
  const totalExtraPagesShown = 8;

  // all possible pages below the current page
  const lowerPages = filter(
    times(totalExtraPagesShown, i => currentPage - i - 1),
    page => page > 0 && page < currentRelease
  ).sort((a, b) => a - b);

  // all possible pages above the current page
  const upperPages = filter(
    times(totalExtraPagesShown, i => currentPage + i + 1),
    page => page > 0 && page < currentRelease
  ).sort((a, b) => a - b);

  // never include the current release page
  let pages = isOnCurrentPage ? [] : [currentPage];
  let lowerIndex = 0;
  let upperIndex = 0;

  // total upper + total lowere + starting pages length
  const pagesPossibleCount =
    lowerPages.length + upperPages.length + pages.length;

  // limit is eithere total extra pages or less than that
  const limitLength =
    pagesPossibleCount >= totalExtraPagesShown
      ? totalExtraPagesShown
      : pagesPossibleCount;

  // add one from upper and lower until pages is the correct size
  while (pages.length < limitLength) {
    const nextLowerValue = lowerPages[lowerPages.length - 1 - lowerIndex];
    const nextUpperValue = upperPages[upperIndex];

    if (nextLowerValue) {
      pages = [nextLowerValue, ...pages];
      lowerIndex += 1;
    }

    if (nextUpperValue) {
      pages = [...pages, nextUpperValue];
      upperIndex += 1;
    }
  }

  const showLeftArrow = currentPage > 1;
  const showRightArrow = currentPage !== currentRelease;
  const showDots = !pages.includes(currentRelease - 1) && currentRelease !== 1;

  return {
    currentRelease,
    currentPage,
    showLeftArrow,
    showRightArrow,
    showDots,
    pages
  };
};

export default connect(
  mapStateToProps,
  null
)(ReleasePagination);
