import React from "react";

import withPageView from "../analytics/PageView";
import HomeDefinition from "../components/home/HomeDefinition";
import HomeBlocks from "../components/home/HomeBlocks";
import SearchBar from "./search-bar/SearchBar";

import "../components/home/Home.css";

// Home Container Component
const Home = () => (
  <div className="Home">
    <div className="Home-banner">
      <HomeDefinition />
      <div className="home-search">
        <SearchBar
          fullWidth
          color="secondary"
          label="🔍  Discover a word or phrase"
          id="lexiconomy-serach"
          margin="normal"
        />
      </div>
    </div>
    <HomeBlocks />
  </div>
);

export default withPageView("Home")(Home);
