// @flow
import React from "react";

import "./LemmaEmptyState.css";

type Props = {
  lemma: string,
  isBase: boolean,
  isComposable: boolean,
  inCurrentRelease: boolean
};

const LemmaEmptyState = ({
  lemma,
  isBase,
  isComposable,
  inCurrentRelease
}: Props) => {
  const wordOrPhrase = isBase ? "word" : "phrase";

  const baseDescription = (
    <div className="lemma-empty-state-explanation">
      <span>place the highest bid for </span>
      {isComposable && (
        <span className="lemma-empty-state-or-compose">
          or{" "}
          <a className="guideLink" href="/tips#composing">
            compose
          </a>{" "}
        </span>
      )}
      <span className="lemma-name">{lemma}</span>
      <span> to be the first to invent it.</span>
    </div>
  );

  const composeDescription = (
    <div className="lemma-empty-state-explanation">
      <span>be the first to invent </span>
      <span className="lemma-name">{lemma}</span>
      <span>
        {" "}
        by{" "}
        <a className="guideLink" href="/tips#composing">
          composing
        </a>{" "}
        it.
      </span>
    </div>
  );

  return (
    <div className="lemma-empty-state-container">
      <div className="lemma-empty-state-description">
        <div className="lemma-empty-state-celebration">
          You've discovered an undefined {wordOrPhrase}!
        </div>
        {isBase ? baseDescription : composeDescription}
      </div>
    </div>
  );
};

export default LemmaEmptyState;
