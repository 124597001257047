import { WEB3_AUTHENTICATION } from "../actions/action-types";

const INITIAL_STATE = { isLoading: true };

export const web3AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEB3_AUTHENTICATION.REQUEST:
      // create set lemma id to loading
      return { ...state, isLoading: true };
    case WEB3_AUTHENTICATION.SUCCESS:
      // create set auction data
      return { ...state, ...action.payload, isLoading: false };
    case WEB3_AUTHENTICATION.FAILURE:
      // is loading is false, reset state, and optionally define network
      return { isLoading: false, ...action.payload };
    default:
  }
  return state;
};
