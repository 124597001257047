// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import { setCompositionFee, modalClose } from "../../actions/actions";
import * as selectors from "../../selectors/selectors";

import { weiToEther, etherToWei } from "../../services/utils";

type Props = {
  lemma: string,
  show: boolean,
  initialValue?: number,
  handleClose: () => void,
  handleSubmit: (number | null) => void
};

type State = {
  value: number,
  dirty: boolean
};

const Transition = props => <Slide direction="up" {...props} />;

class CompositionFeeModal extends Component<Props, State> {
  state = {
    value: 1,
    dirty: false
  };

  static defaultProps = {
    // in wei
    initialValue: 1000000000000000000
  };

  handleInput = event =>
    this.setState({
      value: event.target.value,
      dirty: true
    });

  render() {
    const { value, dirty } = this.state;

    const { show, handleClose, handleSubmit, lemma, initialValue } = this.props;

    const displayValue = dirty ? value : weiToEther(`${initialValue}`);

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="md"
        TransitionComponent={Transition}
        aria-labelledby="submit-composition-dialog-title"
        aria-describedby="submit-composition-dialog-actions"
      >
        <DialogTitle id="submit-composition-dialog-title">
          Set the composition fee for <strong>{lemma}</strong>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="composition-value"
            label="composition fee (eth)"
            value={displayValue}
            onChange={this.handleInput}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="large">
            cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(value);
              handleClose();
            }}
            color="primary"
            autoFocus
            size="large"
          >
            set price
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { id }) => ({
  initialValue: selectors.getLemmaCompositionFee(state, id)
});

const mapDispatchToProps = (dispatch, { id }) => ({
  handleSubmit: value =>
    dispatch(setCompositionFee({ id, value: etherToWei(value) })),
  handleClose: () => dispatch(modalClose("CompositionFeeModal"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompositionFeeModal);
