import { some, last, map, reduce, dropRight } from "lodash";

import {
  separatorRegex,
  whitespaceRegex,
  nonBaseCharacters
} from "./character-categorization";

const hasWhitespaceCharacter = lemma =>
  some(lemma, char => {
    const charPoint = char.codePointAt();
    return nonBaseCharacters[charPoint];
  });

export const isBaseLemma = lemma => {
  if (!lemma) return false;

  // if lemma has illegal or whitespace then it is not a base lemma

  // check for NO WHITESPACE characters
  return !hasWhitespaceCharacter(lemma);
};

const nonGlobalSeparatorRegex = new RegExp(separatorRegex, "im");

export const isComposableLemma = lemma =>
  // if has separator lemmas or has whitespace
  // its composable!
  hasWhitespaceCharacter(lemma) || nonGlobalSeparatorRegex.test(lemma);

export const indicesOf = (string, regex) => {
  if (!regex) {
    return [];
  }
  // force a global regex or else this will infinite loop
  const globalRegex = RegExp(regex, "gumi");
  let stringCopy = string;
  const indices = [];

  // prevent infinite loop by removing the last character if it matches
  const lastCharacter = last(string);
  if (globalRegex.test(lastCharacter)) {
    indices.push(string.length - 1);
    stringCopy = string.slice(0, string.length - 1);
  }

  // while there is a match, continue to look for more
  let match = globalRegex.exec(stringCopy);
  while (match != null) {
    indices.push(match.index);
    match = globalRegex.exec(stringCopy);
  }

  return indices;
};

// transform a lemma string into an array of sub lemmas
export const parseLemma = lemma => {
  if (!lemma) return [];

  const whitespaceIndices = indicesOf(lemma, whitespaceRegex);
  const seperatorIndices = indicesOf(lemma, separatorRegex);

  // sort in ascending order
  const nonNormalIndices = [...whitespaceIndices, ...seperatorIndices].sort(
    (a, b) => a - b
  );

  if (nonNormalIndices === []) return [];

  const subLemmas = [];
  let nonNormalIndex = 0;

  for (let i = 0; i < lemma.length; i += 1) {
    // get the next non normal lemma index or the length of the lemma
    const lemmaEndIndex = nonNormalIndices[nonNormalIndex] || lemma.length;

    // sub lemma is i - the next non lemma index (wrap to prevent mutation)
    const subLemma = `${lemma}`.slice(i, lemmaEndIndex);

    // ignore empty sub lemmas (will be empty if consecutive non normal lemmas)
    if (subLemma) {
      subLemmas.push(subLemma);
    }

    // also add the whitespace or seperator if there are still lemmas left
    if (lemmaEndIndex !== lemma.length) {
      // (wrap to prevent mutation)
      const otherLemma = `${lemma}`.slice(lemmaEndIndex, lemmaEndIndex + 1);

      subLemmas.push(otherLemma);
    }
    // increment i and nonNormalIndex
    i = lemmaEndIndex;
    nonNormalIndex += 1;
  }

  return subLemmas;
};

// validate a potential lemma composition
export const isValidComposition = compositionBreakPoints =>
  !compositionBreakPoints.includes(-1);

// given a canComposeWith function
// find a valid composition with given lemmas
// returns an array of breakpoints for composition
export const findComposition = canComposeWith => async (
  lemmas,
  startIndex = 0,
  endIndex = 0
) => {
  // get the next composable sub lemma
  const currentLemma = lemmas.slice(startIndex, endIndex).join("");
  const isComposable = await canComposeWith(currentLemma);

  // check for base case
  if (endIndex >= lemmas.length) {
    // last sub lemma in compositon is composible return the index otherwise return -1
    return isComposable ? [endIndex] : [-1];
  }

  if (isComposable) {
    // use the current lemma as part of a possible composition
    const remainingComposition = await findComposition(canComposeWith)(
      lemmas,
      endIndex,
      endIndex + 1
    );

    const possibleComposition = [endIndex, ...remainingComposition];

    if (isValidComposition(possibleComposition)) {
      return possibleComposition;
    }
  }

  // else try compose with another sub lemma
  const nextPossibleComposition = await findComposition(canComposeWith)(
    lemmas,
    startIndex,
    endIndex + 1
  );

  return nextPossibleComposition;
};

export const getLemmasToCompose = (lemmasArray, breakpoints) =>
  reduce(
    breakpoints,
    (acc, val, index) => {
      let lemma = "";
      // 0 - first breakpoint is implicit
      if (index === 0) {
        lemma = lemmasArray.slice(0, val).join("");
      } else if (index === breakpoints.length) {
        // last breakpoint to end is implicit
        lemma = lemmasArray.slice(val, lemma.length).join("");
      } else {
        // otherwise, last breakpoint => next breakpoint
        lemma = lemmasArray.slice(breakpoints[index - 1], val).join("");
      }
      acc.push(lemma);
      return acc;
    },
    []
  );

export const mapLemmasToByteBreakPoints = lemmaArray => {
  // TODO: Move
  // NOTE: REQUIRING HERE BECUASE IT BREAK OUR UNIT TESTS
  const Web3 = require("web3");

  const web3 = new Web3(Web3.givenProvider || "ws://127.0.0.1:7545");

  // transform an array of strings into hex
  const byteArray = map(lemmaArray, web3.utils.utf8ToHex);

  // get the length of the hex value of each
  // remove 0x, divide by 2 since each hex char is length two
  const byteLength = map(byteArray, l => l.slice(2).length / 2);

  // get the byte breakpoints by reducing each value to the sum of the previous
  const byteBreakpoints = reduce(
    byteLength,
    (acc = [], len, index) => {
      const previousLength = acc.length ? acc[index - 1] : 0;
      acc.push(previousLength + len);
      return acc;
    },
    []
  );
  // finally drop the last because its implicit
  return dropRight(byteBreakpoints);
};
