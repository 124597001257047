import { merge } from "lodash";
import {
  RELEASE_PAGE_ID,
  RELEASE_DATA,
  RELEASE_CURRENT_DATA,
  RELEASE_TIMER_SUCCESS
} from "../actions/action-types";

const INITIAL_STATE = {};

/* eslint-disable-next-line */
export const releasesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RELEASE_PAGE_ID:
      // set the release id of the page
      return merge({}, state, { page: action.payload.id });
    case RELEASE_DATA.REQUEST:
      // create set release id to loading
      return merge({}, state, { [action.payload.id]: { isLoading: true } });
    case RELEASE_DATA.SUCCESS:
      // create set release data
      return merge({}, state, {
        [action.payload.id]: { isLoading: false, ...action.payload.data }
      });
    case RELEASE_DATA.FAILURE:
      // stop loading
      return merge({}, state, { [action.payload.id]: { isLoading: false } });
    case RELEASE_CURRENT_DATA.REQUEST:
      // save current contract state (release id and min bid)
      return merge({}, state, {
        current: {
          isLoading: true
        }
      });
    case RELEASE_CURRENT_DATA.SUCCESS:
      // save current contract state (release id and min bid)
      return merge({}, state, {
        current: {
          ...action.payload.data,
          isLoading: false
        }
      });

    case RELEASE_TIMER_SUCCESS:
      // save current contract state (release id and min bid)
      return merge({}, state, {
        current: {
          ...action.payload.data,
          timeRemaining: action.payload.timeRemaining,
          currentReleaseBlockSize: action.payload.currentReleaseBlockSize
        }
      });
    case RELEASE_CURRENT_DATA.FAILURE:
      // save current contract state (release id and min bid)
      return merge({}, state, {
        current: {
          isLoading: false
        }
      });

    default:
  }
  return state;
};
