// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import withPageView from "../analytics/PageView";

import { lemmaPageLoad } from "../actions/actions";
import {
  createLemmaId,
  makeLemmaIdReadable,
  getLemmaColor,
  hasValidNetwork
} from "../services/utils";
import * as selectors from "../selectors/selectors";

import Loading from "../components/common/Loading";
import InvalidNetwork from "../components/common/InvalidNetwork";

import LemmaActionsContainer from "./lemma/LemmaActionsContainer";
import LemmaData from "./lemma/LemmaDataContainer";
import LemmaCharts from "./lemma/LemmaCharts";

type Props = {
  lemma?: string,
  id: string,
  isLoading: boolean,
  title: string,
  fetchData: Object => void,
  isCached: boolean
};

type State = {|
  +validNetwork: boolean
|};

// Lemma Page Container Component
class LemmaPage extends Component<Props, State> {
  static defaultProps = {
    lemma: ""
  };

  state = {
    validNetwork: true
  };

  // on mount get lemma id start page load saga
  async componentDidMount() {
    const { id, lemma, fetchData } = this.props;
    fetchData({ id, lemma });

    const validNetwork = await hasValidNetwork();

    this.setState({
      validNetwork
    });
  }

  // fetch data if a new lemma is loaded
  componentDidUpdate(prevProps) {
    const { id, lemma, fetchData } = this.props;

    if (prevProps.id !== id) {
      fetchData({ id, lemma });
    }
  }

  render() {
    const { validNetwork } = this.state;
    const { isLoading = false, lemma, title, id, isCached } = this.props;

    if (!validNetwork) {
      return <InvalidNetwork />;
    }

    const lemmaColor = getLemmaColor(lemma || id);

    // remove the alpha to darken the color
    const titleUnderlineColor = lemmaColor.slice(0, lemmaColor.length - 2);

    if (isLoading && !isCached) {
      // return loading screen
      return (
        <div className="lemma-page-container">
          {/* display the title */}
          <div
            className="lemma-title font-space-mono"
            style={{
              borderBottomColor: titleUnderlineColor
            }}
          >
            {title}
          </div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="lemma-page-container">
        {/* display the title */}
        <div
          className="lemma-title font-space-mono"
          style={{
            borderBottomColor: titleUnderlineColor
          }}
        >
          {title}
        </div>
        <LemmaData lemma={lemma} id={id} />
        {/* only show if lemma is defined */}
        {lemma && <LemmaCharts id={id} lemma={lemma} />}
        {lemma && <LemmaActionsContainer lemma={lemma} id={id} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let id;

  // get the page params
  const { id: pageParamId, lemma } = ownProps.match.params;

  // https://github.com/ReactTraining/history/issues/505
  // we double encode on search
  // react router decodes on push AND decodes match.params
  // check to see if we should decode once or twice
  let decodedLemma = lemma ? lemma.trim().toLowerCase() : ""

  try {
    decodedLemma = lemma ? decodeURIComponent(lemma)
      .trim()
      .toLowerCase() : lemma;
  } catch (error) {
    // do nothing
  }

  // if we have id in the url then set it
  if (pageParamId) {
    id = pageParamId;
  }
  // else lets calculate it
  else {
    id = createLemmaId(decodedLemma);
  }

  return {
    isLoading: selectors.isLoadingLemmaData(state, id),
    id,
    lemma: decodedLemma || makeLemmaIdReadable(id),
    title: decodedLemma || makeLemmaIdReadable(id),
    isCached: selectors.hasCachedLemmaData(state, id)
  };
};

export default withPageView("Lemma")(
  withRouter(
    connect(
      mapStateToProps,
      {
        fetchData: lemmaPageLoad
      }
    )(LemmaPage)
  )
);
