// @flow
import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { history } from "../store/configureStore";

import Routes from "./Routes";
import Theme from "../theme";
import Layout from "./Layout";

type Props = {
  store: Object
};

// Configures the routes
const Root = ({ store }: Props) => (
  <Provider store={store}>
    <MuiThemeProvider theme={Theme}>
      <ConnectedRouter history={history}>
        <Layout>
          <Routes />
        </Layout>
      </ConnectedRouter>
    </MuiThemeProvider>
  </Provider>
);

export default Root;
