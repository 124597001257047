// @fow
import React, { type Node } from "react";
import { withRouter } from "react-router-dom";

import NavBar from "../components/common/NavBar";
import Footer from "../components/footer/Footer";

import HiddenErrorBoundary from "../components/common/HiddenErrorBoundary";
import PageErrorBoundary from "../components/common/PageErrorBoundary";
import AuthenticationWeb3 from "./AuthenticationWeb3";
import MessageBarContainer from "./message/MessageBarContainer";
import ModalContainer from "./modals/ModalContainer";

type Props = {
  children: Node,
  location: {
    pathname: string
  }
};

// Configures the layout of the website
const Layout = ({ children, location }: Props) => (
  <AuthenticationWeb3>
    {location.pathname !== "/" && (
      <HiddenErrorBoundary>
        <NavBar />
      </HiddenErrorBoundary>
    )}
    <main id="main" className="main-content">
      <PageErrorBoundary>{children}</PageErrorBoundary>
      <MessageBarContainer />
      <ModalContainer />
    </main>
    {location.pathname !== "/" && (
      <HiddenErrorBoundary>
        <Footer />
      </HiddenErrorBoundary>
    )}
  </AuthenticationWeb3>
);

export default withRouter(Layout);
