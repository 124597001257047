// @flow
import React, { Component, type Element } from "react";

import { track } from "./analytics";

type Props = {||};

type State = {|
  mountTime: number
|};

const withPageView = (name: string) => (WrappedComponent: Element<any>) =>
  class PageView extends Component<Props, State> {
    state = {
      mountTime: 0
    };

    componentDidMount() {
      // Check how often Page !== page. If never, remove 'Page'.
      track("Page View", { Page: name });

      this.setState(() => ({
        mountTime: Date.now()
      }));
    }

    componentWillUnmount() {
      const { mountTime } = this.state;
      if (!mountTime) return;

      const duration = Math.round((Date.now() - mountTime) / 1000);

      track("Page Leave", {
        Page: name,
        duration
      });
    }

    render() {
      return <WrappedComponent />;
    }
  };

export default withPageView;
