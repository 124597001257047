// @flow
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { releaseTimerLoad, releaseTimerClick } from "../../actions/actions";
import {
  getCurrentReleaseTimeRemaining,
  getCurrentReleaseBlockSize
} from "../../selectors/selectors";

import "./ReleaseTimer.css";

type Props = {
  secondsRemaining: number,
  releaseTimerLoad: typeof releaseTimerLoad,
  track: number => void,
  currentReleaseBlockSize: number
};

type State = {
  timeLeft: number,
  hours: number,
  minutes: number,
  seconds: number,
  intervalId: IntervalID
};

class ReleaseTimer extends React.Component<Props, State> {
  state = {
    timeLeft: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  componentDidMount() {
    this.props.releaseTimerLoad();
  }

  componentDidUpdate(prevProps) {
    const { secondsRemaining, currentReleaseBlockSize } = this.props;
    // start a timer once we get the time remaining
    if (
      currentReleaseBlockSize > 0 &&
      prevProps.secondsRemaining === undefined &&
      secondsRemaining !== undefined
    ) {
      this.formatRemainingTime(secondsRemaining);

      const intervalId = setInterval(() => {
        this.formatRemainingTime(this.state.timeLeft - 1);
      }, 1000);

      this.setState(prevState => ({
        ...prevState,
        intervalId
      }));
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer = () => {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  };

  formatRemainingTime = timeLeft => {
    // stop the timer
    if (timeLeft < 0) {
      this.clearTimer();
      return;
    }

    const hours = parseInt(timeLeft / 3600, 10);
    const timeAfterHours = timeLeft % 3600;

    const minutes = parseInt(timeAfterHours / 60, 10);
    const timeAfterMinutes = timeAfterHours % 60;

    const seconds = parseInt(timeAfterMinutes, 10);

    this.setState(prevState => ({
      ...prevState,
      timeLeft,
      hours,
      minutes,
      seconds
    }));
  };

  formatTime = time => `0${time}`.slice(-2);

  render() {
    const { secondsRemaining, track, currentReleaseBlockSize } = this.props;

    if (currentReleaseBlockSize === 0 || secondsRemaining === undefined)
      return null;

    const { seconds, minutes, hours } = this.state;

    return (
      <Link
        className="release-timer"
        to="/release/current"
        onClick={() => track(secondsRemaining)}
      >
        {this.formatTime(hours)}:{this.formatTime(minutes)}:
        {this.formatTime(seconds)}
      </Link>
    );
  }
}

const mapStateToProps = state => ({
  currentReleaseBlockSize: getCurrentReleaseBlockSize(state),
  secondsRemaining: getCurrentReleaseTimeRemaining(state)
});

const mapDispatchToProps = dispatch => ({
  releaseTimerLoad: () => dispatch(releaseTimerLoad()),
  track: time => dispatch(releaseTimerClick(time))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseTimer);
