// @flow
import type { Saga } from "redux-saga";
import { delay } from "redux-saga";
import { call, take, put, actionChannel } from "redux-saga/effects";

import { isEmpty } from "lodash";

import { MESSAGE_CLOSE } from "../actions/action-types";
import { messageOpen } from "../actions/actions";

type Message = {
  kind?: string,
  text?: string
};

const hasMessage = ({ message = {} }: { message: Message }) =>
  !isEmpty(message.text);

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

// watch all actions for payloads with a message
export function* watchMessages(): Saga {
  // 1- Create a channel for request actions
  const messageChannel = yield actionChannel(({ payload }) =>
    hasMessage(payload)
  );

  while (true) {
    // 2- take from the channel
    const {
      payload: { message }
    } = yield take(messageChannel);

    // 3- Put to open the message
    yield put(messageOpen(message));

    // 4- Wait for the message to close
    yield take(MESSAGE_CLOSE);

    // 5- Wait for the message to disappear
    yield call(delay, 500);

    // 6- Continue while their are messages in the channel
  }
}
