// @flow
import type { Saga } from "redux-saga";
import {
  takeLatest,
  put,
  call,
  cancelled,
  select,
  all
} from "redux-saga/effects";
import { push } from "connected-react-router";

import { minBy, sortBy, reduce } from "lodash";

import { RELEASE_PAGE_LOAD, RELEASE_TIMER_LOAD } from "../actions/action-types";

import {
  releaseData,
  releasePageId,
  releaseCurrentData,
  releaseTimerSuccess
} from "../actions/actions";

import { getCurrentReleaseId } from "../selectors/selectors";

import {
  LEXICONOMY_CONTRACT,
  getContractDetails
} from "../services/contracts/details";
import {
  contractCall,
  getPastEvents
} from "../services/contracts/transactions";

import { bytesToString, getBlockNumber, parseUInt256 } from "../services/utils";
/** *************************************************************************** */
/** *************************** Subroutines *********************************** */
/** *************************************************************************** */

export function* fetchCurrentReleaseId() {
  // always make sure we have the cotnract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return null;

  try {
    // get dictionary and owner data in parallel
    const id = yield call(contractCall, {
      abi,
      address,
      method: "lemmaReleaseId"
    });

    return id ? parseUInt256(id) : null;
  } catch (error) {
    // if an error return nothing
    return null;
  }
}

// get the current release id and min bid from the contract
function* fetchCurrentReleaseData() {
  // always make sure we have the cotnract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return {};

  try {
    // get dictionary and owner data in parallel
    const { id, minBid } = yield all({
      id: call(contractCall, {
        abi,
        address,
        method: "lemmaReleaseId"
      }),
      minBid: call(contractCall, {
        abi,
        address,
        method: "minBid"
      })
    });

    if (id === null) return {};

    return {
      id: parseUInt256(id),
      minBid: parseUInt256(minBid)
    };
  } catch (error) {
    // if an error return nothing
    return {};
  }
}

const areBidsEqual = (a, b) =>
  a.lemmaId === b.lemmaId &&
  parseUInt256(a.price) === parseUInt256(b.price) &&
  a.bidder === b.bidder;

const hasMatchingFailedBid = (bid, failedBids) =>
  failedBids.some(({ returnValues }) => areBidsEqual(bid, returnValues));

function* fetchReleaseData(id) {
  // always make sure we have the cotnract data
  const { abi, address } = yield call(getContractDetails, LEXICONOMY_CONTRACT);

  // if we do not have contract data return
  if (!abi || !address) return {};

  try {
    // TODO ADD SPECIAL CASE FOR RELEASE ID = 0

    const [successfulBids, failedBids] = yield all([
      call(getPastEvents, {
        abi,
        address,
        event: "BidSuccess",
        filter: {
          lemmaReleaseId: id
        }
      }),
      call(getPastEvents, {
        abi,
        address,
        event: "BidLoss",
        filter: {
          lemmaReleaseId: id
        }
      })
    ]);

    // check for no bids
    if (!successfulBids)
      return {
        minBid: 0
      };

    const {
      returnValues: { price: minBidRaw }
    } =
      successfulBids.length >= 25
        ? minBy(successfulBids, ({ returnValues: { price } }) =>
            parseUInt256(price)
          )
        : { returnValues: { price: "0" } };

    const minBid = parseUInt256(minBidRaw);

    // highest 25 bids are the bids that do not have a corresponding failed bid
    const highestBids = reduce(
      successfulBids,
      (finalBids, { returnValues: bid }) => {
        const isNotInRelease =
          failedBids && hasMatchingFailedBid(bid, failedBids);

        // bid failed, ignore it
        if (isNotInRelease) return finalBids;

        const { lemmaId, price, lemma, bidder } = bid;

        // sometimes web3 returns a BigNumber instance instead of a number or string
        const safePrice = parseUInt256(price);

        // else add the bid
        return {
          ...finalBids,
          [lemmaId]: {
            id: lemmaId,
            price: safePrice,
            bidder,
            // turn bytes into a readable string
            lemma: bytesToString(lemma)
          }
        };
      },
      {}
    );

    // sort bids in descending order
    const sortedBids = sortBy(highestBids, ({ price }) => -price);

    return {
      minBid,
      bids: sortedBids
    };
  } catch (error) {
    console.error(error);
    // if an error return nothing
    return {};
  }
}

function* loadCurrentReleaseData() {
  // try fetch current release data
  try {
    // get the current release id and min bid from the contract
    const currentReleaseData = yield call(fetchCurrentReleaseData);

    // save the current release block and min bid
    yield put(releaseCurrentData.success(currentReleaseData));
  } catch (error) {
    yield put(
      releaseCurrentData.failure(
        "Unable to load the current release. Please refresh the page!"
      )
    );
  }
}

export function* loadReleaseData({ payload }) {
  let { id } = payload;

  // get info about the current release
  yield call(loadCurrentReleaseData);

  const currentReleaseId = yield select(getCurrentReleaseId);

  if (currentReleaseId === undefined) return;

  // if no id, get the current release id
  if (!id) {
    // get the id from the state
    id = currentReleaseId;
  }

  if (id > currentReleaseId || id < 1) {
    yield put(push(`/release/${currentReleaseId}`));
    return;
  }

  // if we have no id now, the current release fetch failed so exit
  if (!id) return;

  try {
    // update current id
    yield put(releasePageId(parseUInt256(id)));

    // start release data request
    yield put(releaseData.request(id));

    // get the fetched data
    const data = yield call(fetchReleaseData, id);

    // store the data in the state
    yield put(releaseData.success(id, data));
  } catch (error) {
    // catch any errors
    console.error(error);

    // check for cancel
    if (!(yield cancelled())) {
      // put a failure
      yield put(
        releaseData.failure(id, `Unable to get data for release block #${id}.`)
      );
    }
  }
}

function* fetchReleaseTimerData() {
  try {
    // always make sure we have the cotnract data
    const { abi, address } = yield call(
      getContractDetails,
      LEXICONOMY_CONTRACT
    );

    // if we do not have contract data return
    if (!abi || !address) return {};

    // if we don't have an account return
    const {
      currentReleaseBlockSize,
      nextReleaseBlock,
      secondsPerBlock
    } = yield all({
      currentReleaseBlockSize: call(contractCall, {
        abi,
        address,
        method: "currentReleaseBlockSize"
      }),
      nextReleaseBlock: call(contractCall, {
        abi,
        address,
        method: "nextLemmaReleaseBlock"
      }),
      secondsPerBlock: call(contractCall, {
        abi,
        address,
        method: "secondsPerBlock"
      })
    });

    const currentBlock = yield call(getBlockNumber);

    const blocksRemaining =
      parseUInt256(nextReleaseBlock) - parseUInt256(currentBlock);

    const timeRemaining =
      parseUInt256(blocksRemaining) * parseUInt256(secondsPerBlock);

    yield put(
      releaseTimerSuccess({
        timeRemaining: timeRemaining > 0 ? timeRemaining : 0,
        currentReleaseBlockSize: parseUInt256(currentReleaseBlockSize)
      })
    );
  } catch (error) {
    console.error(error);
  }
  return {};
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

export function* watchReleasePageLoad(): Saga {
  yield all([
    takeLatest(RELEASE_PAGE_LOAD, loadReleaseData),
    takeLatest(RELEASE_TIMER_LOAD, fetchReleaseTimerData)
  ]);
}
