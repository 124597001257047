// @flow
import React from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

import { modalClose, web3Initialize } from "../../actions/actions";

import MetaMaskIcon from "./download-metamask.png";

import "./MetaMaskModal.css";

type Props = {
  show: boolean,
  onClose: () => void,
  authenticate: () => void
};

const Transition = props => <Slide direction="up" {...props} />;

const MetaMaskModal = ({ show, onClose, authenticate }: Props) => (
  <Dialog
    open={show}
    onClose={onClose}
    maxWidth="xs"
    TransitionComponent={Transition}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Sign into MetaMask</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        To interact with the lexiconomy, you must first download and sign into
        MetaMask.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={authenticate} color="secondary">
        i will sign into MetaMask
      </Button>
      <Button
        href="https://metamask.io/"
        target="_blank"
        color="secondary"
        autoFocus
      >
        {/* i need MetaMask */}
        <img
          className="metamask-image"
          src={MetaMaskIcon}
          alt="download-metamask"
        />
      </Button>
    </DialogActions>
  </Dialog>
);

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(modalClose("MetaMaskModal")),
  authenticate: () => {
    dispatch(web3Initialize());
    dispatch(modalClose("MetaMaskModal"));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(MetaMaskModal);
