import React from "react";
import { Link } from "react-router-dom";

import * as publicLemmas from "../services/public-words";

import withPageView from "../analytics/PageView";

import CompositonGraphic from "../components/tips/composition-graphic.png";

import "../components/tips/Tips.css";

const TipsPage = () => (
  <div className="tipsPage">
    <h1 className="tipsSectionTitle font-space-mono">
      guide to the lexiconomy{" "}
      <span role="img" aria-label="books">
        📚
      </span>
    </h1>
    <div id="ownership" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        ownership{" "}
        <span role="img" aria-label="key">
          🗝
        </span>
      </h2>

      <p className="guideSectionDetails">
        the{" "}
        <a
          className="guideLink"
          href="http://erc721.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ERC 721
        </a>{" "}
        standard enables provable ownership of lemmas (words and phrases). When
        you successfully bid for or compose a lemma on the lexiconomy, you have
        full ownership of it.
      </p>
      <p className="guideSectionDetails">
        as the owner of word or phrase, you have the power to{" "}
        <strong>define</strong> it, <strong>sell</strong> it to others, and set
        the price others must pay to <strong>compose</strong> with it. Owners
        can compose new lemmas with their lemmas for free.
      </p>
      <p className="guideSectionDetails">
        Ownership extends beyond the lexiconomy, you can use your lemmas in any
        ERC-721 compatible application!
      </p>
    </div>
    <div id="bidding" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        inventing a word{" "}
        <span role="img" aria-label="thought">
          💭
        </span>
      </h2>
      <p className="guideSectionDetails">
        Words form the basis of the lexiconomy. Without words, no one could
        compose a phrase. A word, as you can guess, is a lemma without
        whitespace or punctuation.{" "}
      </p>
      <p className="guideSectionDetails">
        Words are released in batches to allow fairer distribution of words. New
        words are released daily after a 24 hour auction, known as a{" "}
        <strong>release auction</strong>. A release auction doesn't start until
        the first bid has been placed. At the end of a release auction, the
        words associated with <strong>highest 25 bids</strong> are released and
        their ownership is transferred to the corresponding bidders. Once
        released, words can be used by others to compose new lemmas!
      </p>
      <p className="guideSectionDetails">
        To place a bid, use the <strong>bid</strong> button on the lemma’s page.
        Remember you will only see the bid button for words, you cannot bid for
        phrases.
      </p>
    </div>
    <div id="composing" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        composing{" "}
        <span role="img" aria-label="writing">
          ✍️
        </span>
      </h2>
      <p className="guideSectionDetails">
        Composition is the other method of inventing a lemma. The relationship
        between lemmas is recursive, one lemma can be made up of, or{" "}
        <strong>composed</strong> of, many smaller lemmas.
      </p>
      <p className="guideSectionDetails">
        In order to compose a new lemma, there must exist a combination of owned
        and public lemmas that make it up. To try compose a lemma, use the{" "}
        <strong>compose</strong> button on the lemma’s page. If you aren’t able
        to compose a lemma that means there are words that haven’t been invented
        yet, so place bids for them to be in the next release!
      </p>
      <p className="guideSectionDetails">
        The price of a composition is calculated by the composition fee
        associated with each of the lemmas used.{" "}
        <a className="guideLink" href="/tips#public">
          Public lemmas
        </a>{" "}
        and whitespace will always have the same composition fee, which is
        recalculated after every release auction. On the other hand, owners of
        lemmas can set their own composition fees. If an owner never sets a
        custom composition fee, the fee defaults to the public lemma composition
        fee.
      </p>
      <p className="guideSectionDetails">
        When others use your lemma in composition, you will be compensated based
        off the composition fee you’ve set. For security purposes, the
        lexiconomy cannot directly deposit earnings into your account. Instead,
        you will see a <strong>withdraw earnings</strong> button at the bottom
        of the screen when you have ether waiting for you!
      </p>

      <div id="composition-example" className="tipsSection">
        <h3 className="guideSectionSubHeader">Composition Example</h3>
        <p className="guideSectionDetails">
          Imagine we want to compose the phrase <strong>the cat is fat</strong>.
          In order to do so, we need a combination of the lemmas that compose
          the <strong>the cat is fat</strong> to be owned or public lemmas.
          Below are some of the possible composition combinations
        </p>
        <img
          className="compositionGraphic"
          src={CompositonGraphic}
          alt="how to compose 'the cat is fat'"
        />
      </div>
    </div>
    <div id="definitions" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        definitions{" "}
        <span role="img" aria-label="key">
          📝
        </span>
      </h2>
      <p className="guideSectionDetails">
        All definitions in the lexiconomy are user created. Lemma’s can only be
        defined by their owner. If you want to define a lemma that doesn’t
        exist, bid for it or compose it to get ownership. If you want to define
        an already existing lemma, buy it in a sale auction or make an offer the
        owner can’t refuse!
      </p>
      <p className="guideSectionDetails">
        To define a lemma you own, click the <strong>define</strong> or{" "}
        <strong>redefine</strong> button on its page! Although all definitions
        are recorded and persisted in the lexiconomy, only the latest definition
        is currently displayed. This will change in the future, so stayed tuned!
      </p>
    </div>
    <div id="trading" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        buying and selling{" "}
        <span role="img" aria-label="exchange">
          💱
        </span>
      </h2>
      <p className="guideSectionDetails">
        If you would like to own an existing lemma, one option is to buy the
        lemma when it's up for sale.
      </p>
      <p className="guideSectionDetails">
        If you are the owner of a lemma, you, and only you, can initiate a sale
        auction for that lemma.
      </p>
      <p className="guideSectionDetails">
        sale auctions follow a{" "}
        <a
          className="guideLink"
          href="https://en.wikipedia.org/wiki/Dutch_auction"
          target="_blank"
          rel="noopener noreferrer"
        >
          dutch auction
        </a>{" "}
        format, in which the first to bid above the going price wins the
        auction. if an auction expires, the owner of the lemma, must manually
        cancel the expired auction before starting another.
      </p>
      <p className="guideSectionDetails">
        To sell or buy a lemma, use the <strong>sell</strong> and{" "}
        <strong>buy</strong> buttons when on a lemma’s page.
      </p>
    </div>
    <div id="offers" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        Offers{" "}
        <span role="img" aria-label="exchange">
          💰
        </span>
      </h2>
      <p className="guideSectionDetails">
        Another option for buying an existing lemma is to make an offer. You are
        always able to make an offer to buy a lemma from someone else. If owner
        accepts, you are automatically transferred ownership of the lemma and
        the owner receives the bid.
      </p>
      <p className="guideSectionDetails">
        There can only be one offer, the highest offer, for a lemma at a time,
        so watch out for others outbidding you! You can also cancel your offer
        at any time.
      </p>
      <p className="guideSectionDetails">
        To make an offer, click the <strong>make offer</strong> button on a
        lemma’s page.
      </p>
    </div>
    <div id="public" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        public lemmas{" "}
        <span role="img" aria-label="free">
          🈂️
        </span>
      </h2>
      <p className="guideSectionDetails">
        some lemmas are public, meaning they are always available to compose
        with for a fixed fee, but cannot be owned by an individual.
      </p>
      <p className="guideSectionDetails">
        Currently, the public lemma composition fee is calculated based off the
        average price in previous release auction. This is subject to change in
        the future.
      </p>
      <p className="guideSectionDetails">
        latin vowels, punctuation, symbols, and natural numbers are all public
        words. other commons words, like{" "}
        <Link className="guideLink" to="/lemma/the">
          the
        </Link>
        {""}, are also free. All public lemmas are listed below.
      </p>

      <div id="public-expamples" className="tipsSection">
        <div className="publicWordListTitle">Punctuation and Symbols</div>
        <div className="publicWordList">
          {publicLemmas.symbols.map(lemma => (
            <Link
              to={`/lemma/${encodeURIComponent(encodeURIComponent(lemma))}`}
              key={lemma}
            >
              {lemma}
            </Link>
          ))}
        </div>

        <div className="publicWordListTitle">Vowels</div>
        <div className="publicWordList">
          {publicLemmas.vowels.map(lemma => (
            <Link
              to={`/lemma/${encodeURIComponent(encodeURIComponent(lemma))}`}
              key={lemma}
            >
              {lemma}
            </Link>
          ))}
        </div>

        <div className="publicWordListTitle">Numbers</div>
        <div className="publicWordList">
          {publicLemmas.numbers.map(lemma => (
            <Link
              to={`/lemma/${encodeURIComponent(encodeURIComponent(lemma))}`}
              key={lemma}
            >
              {lemma}
            </Link>
          ))}
        </div>

        <div className="publicWordListTitle">Words</div>
        <div className="publicWordList">
          {publicLemmas.words.map(lemma => (
            <Link
              to={`/lemma/${encodeURIComponent(encodeURIComponent(lemma))}`}
              key={lemma}
            >
              {lemma}
            </Link>
          ))}
        </div>

        <div className="publicWordListTitle">Suffixes</div>
        <div className="publicWordList">
          {publicLemmas.suffixes.map(lemma => (
            <Link
              to={`/lemma/${encodeURIComponent(encodeURIComponent(lemma))}`}
              key={lemma}
            >
              {lemma}
            </Link>
          ))}
        </div>
      </div>
    </div>
    <div id="web3-issues" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        Web3 (issues){" "}
        <span role="img" aria-label="injured">
          🤕
        </span>
      </h2>
      <p className="guideSectionDetails">
        the lexiconomy is built entirely on ethereum! all of the lexiconomy's
        data is decentralized and stored on the ethereum network.
      </p>
      <p className="guideSectionDetails">
        this is an incredible accomplishment, but decentralized applications are
        not a fully matured technology and you may noticed inconsistent loading
        times and temporary data reliability issue.
      </p>
      <p className="guideSectionDetails">
        we are actively working to find a solution for this! But if you
        encounter missing data or long loading times,{" "}
        <strong>refresh your page</strong> a few times and until its fixed.
      </p>
    </div>
    <div id="illegal" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        illegal lemmas{" "}
        <span role="img" aria-label="siren">
          🚨
        </span>
      </h2>
      <p className="guideSectionDetails">
        As you’ve have probably noticed, only lowercase letters are allowed in
        the lexiconomy! This is to protect owners from others duplicating their
        lemmas with different casing. In the same vein, there are a few other
        rules enforced on the lexiconomy.
      </p>

      <ul className="guideSectionDetails">
        <li>No uppercase letters</li>
        <li>No trailing whitespace</li>
        <li>Only single spaces allowed between words and punctuation</li>
      </ul>
    </div>
    <div id="illegal" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        fees{" "}
        <span role="img" aria-label="siren">
          💳
        </span>
      </h2>
      <p className="guideSectionDetails">
        When building the lexiconomy, we elected not to raise money through an
        ICO or traditional means. Instead, we chose a transaction fee model. A
        transaction fee model aligns with the lexiconomy’s self-sustaining, long
        term vision. Only a 4% fee is taken on sales, offers, and composition
        fee earnings.
      </p>
    </div>
    <div id="appreciation" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        Thank you{" "}
        <span role="img" aria-label="happy cat">
          😸
        </span>
      </h2>
      <p className="guideSectionDetails">
        <a
          className="guideLink"
          href="https://www.ethereum.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ethereum
        </a>
        ,{" "}
        <a
          className="guideLink"
          href="http://erc721.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ERC 721
        </a>
        ,{" "}
        <a
          className="guideLink"
          href="https://metamask.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          metamask
        </a>
        ,{" "}
        <a
          className="guideLink"
          href="https://www.cryptokitties.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          cryptokitties
        </a>
        ,{" "}
        <a
          className="guideLink"
          href="https://0x.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          0x
        </a>
        ,{" "}
        <a
          className="guideLink"
          href="https://decentraland.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          decentraland
        </a>{" "}
        and everyone else in the community!
      </p>
    </div>

    <div id="questions" className="guideSection">
      <h2 className="guideSectionTitle font-space-mono">
        get involved!{" "}
        <span role="img" aria-label="excited">
          🤩
        </span>
      </h2>
      <p className="guideSectionDetails">
        join the community{" "}
        <a className="emailUs" href="https://discord.gg/QjUfUjp">
          discord
        </a>{" "}
        or reach out at{" "}
        <a className="emailUs" href="mailto:sixfour@lexiconomy.org">
          sixfour@lexiconomy.org
        </a>
      </p>
    </div>
  </div>
);

export default withPageView("Guide")(TipsPage);
