// @flow
import React from "react";
import classNames from "classnames";

import CheckCircleIcon from "react-feather/dist/icons/check-circle";
import ErrorIcon from "react-feather/dist/icons/alert-circle";
import InfoIcon from "react-feather/dist/icons/info";
import CloseIcon from "react-feather/dist/icons/x";
import WarningIcon from "react-feather/dist/icons/alert-triangle";

import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: "#77dd77"
  },
  error: {
    backgroundColor: "#ff6961"
  },
  info: {
    backgroundColor: "#aec6cf"
  },
  warning: {
    backgroundColor: "#ffb347"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontSize: 16
  }
});

type Variant = "success" | "warning" | "error" | "info";

type Props = {
  classes: Object,
  className: string,
  message: string,
  onClose: () => void,
  variant: Variant
};

const MessageBar = (props: Props) => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

export default withStyles(styles)(MessageBar);
