import React from "react";

import "./HomeDefinition.css";

const HomeDefinition = () => (
  <div className="home-definition-items">
    <div className="lexiconomy-title font-space-mono">Lexiconomy</div>
    <div className="lexiconomy-pronounciation">/ leksi-kän-əmē /</div>
    <div className="lexiconomy-part-of-speech">noun</div>
    <div className="lexiconomy-definition">
      {"1. the world's decentralized dictionary."}
    </div>
  </div>
);

export default HomeDefinition;
