// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import { makeLemmaBid, modalClose } from "../../actions/actions";
import * as selectors from "../../selectors/selectors";

import { weiToEther, displayWeiValueInEther } from "../../services/utils";

type Props = {
  lemma: string,
  show: boolean,
  initialValue?: number,
  minBid?: number,
  handleClose: () => void,
  handleSubmit: (number | null) => void
};

type State = {
  value: number,
  dirty: boolean
};

const MIN_BID_WEI = 100000000000000;

const Transition = props => <Slide direction="up" {...props} />;

class BidModal extends Component<Props, State> {
  state = {
    value: 1,
    dirty: false
  };

  static defaultProps = {
    // in wei
    initialValue: 1000000000000000000
  };

  handleInput = event =>
    this.setState({
      value: event.target.value,
      dirty: true
    });

  render() {
    const { value, dirty } = this.state;

    const {
      show,
      handleClose,
      handleSubmit,
      lemma,
      initialValue,
      minBid = MIN_BID_WEI
    } = this.props;

    const displayValue = dirty ? value : weiToEther(`${initialValue}`);

    const parsedValue = parseFloat(value || 0);

    const canSubmit = parsedValue > weiToEther(minBid);

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="md"
        TransitionComponent={Transition}
        aria-labelledby="submit-bid-dialog-title"
        aria-describedby="submit-bid-dialog-actions"
      >
        <DialogTitle id="submit-bid-dialog-title">
          Submit your bid for <strong>{lemma}</strong>
        </DialogTitle>
        <DialogContent>
          {(!canSubmit && parsedValue > 0) && (
            <div>bid must be greater than {displayWeiValueInEther(minBid)}</div>
          )}
          <TextField
            id="bid-value"
            label="bid price (eth)"
            value={displayValue}
            onChange={this.handleInput}
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="large">
            cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(value);
              handleClose();
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={!canSubmit}
          >
            bid
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { id }) => ({
  minBid:
    selectors.getLemmaBidValue(state, id) ||
    selectors.getCurrentReleaseMinBid(state) ||
    MIN_BID_WEI,
  initialValue:
    selectors.getLemmaBidValue(state, id) ||
    selectors.getCurrentReleaseMinBid(state) ||
    1000000000000000000
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSubmit: value =>
    dispatch(makeLemmaBid({ lemma: ownProps.lemma, value })),
  handleClose: () => dispatch(modalClose("BidModal"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BidModal);
