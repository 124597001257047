// @flow
import React from "react";

type Props = {
  id: string
};

const ReleaseTitle = ({ id }: Props) => (
  <div className="release-title font-space-mono">Release Auction #{id}</div>
);

export default ReleaseTitle;
