import { createMuiTheme } from "@material-ui/core/styles";

// create material ui theme
const Theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#424242"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contast with palette.primary.main
    },
    secondary: {
      // #0f9861
      // main: "#ffab91"
      main: "#0f9861"
      // dark: will be calculated from palette.secondary.main,
    }
    // error: will use the default color
  },
  typography: {
    useNextVariants: true
  }
});

export default Theme;
