// @flow
// eslint-disable-next-line
import React, { type Node } from "react";
import { connect } from "react-redux";

import { isWeb3Authenticated } from "../../selectors/selectors";

type Props = {
  children: Node,
  isAuthenticated: boolean
};

// Only display children if web3 is authenticated
const Web3Required = ({ isAuthenticated, children }: Props) => {
  if (!isAuthenticated) return null;

  return children;
};

const mapStateToProps = state => ({
  isAuthenticated: isWeb3Authenticated(state)
});

export default connect(mapStateToProps)(Web3Required);
