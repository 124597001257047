import { combineReducers } from "redux";
import { merge } from "lodash";

import { SALE_AUCTION_DATA } from "../actions/action-types";

const saleAuctionsReducer = (state = {}, action) => {
  switch (action.type) {
    case SALE_AUCTION_DATA.REQUEST:
      // create set lemma id to loading
      return merge({}, state, { [action.payload.id]: { isLoading: true } });
    case SALE_AUCTION_DATA.SUCCESS:
      // create set auction data
      return merge({}, state, {
        [action.payload.id]: { isLoading: false, ...action.payload.data }
      });
    case SALE_AUCTION_DATA.FAILURE:
      // stop loading
      return merge({}, state, {
        [action.payload.id]: { isLoading: false }
      });
    default:
  }
  return state;
};

// combine into auctions reducer
export const auctionsReducer = combineReducers({
  sale: saleAuctionsReducer
});
