import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/browser";

import * as serviceWorker from "./serviceWorker";

import configureStore from "./store/configureStore";
import rootSaga from "./sagas/sagas";

import Root from "./containers/Root";

import "./index.css";

require("dotenv").config();

if (process.env.NODE_ENV === "production") {
  console.log("Initializing Sentry...");
  // init sentry
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });

  Sentry.configureScope(scope => {
    scope.setTag("environment", process.env.NODE_ENV);
  });
  console.log("Sentry initialized.");
}

const store = configureStore();

// run sagas
store.runSaga(rootSaga);

render(<Root store={store} />, document.getElementById("root"));

// disable offline capabilities
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
