import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { web3AuthReducer as web3 } from "./web3-auth-reducer";
import {
  messagesReducer as messages,
  modalsReducer as modals
} from "./messages-reducer";
import { lemmasReducer as lemmas } from "./lemmas-reducer";
import { auctionsReducer as auctions } from "./auctions-reducer";
import { releasesReducer as releases } from "./releases-reducer";
import { fundsReducer as funds } from "./funds-reducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    web3,
    messages,
    modals,
    lemmas,
    auctions,
    releases,
    funds
  });

export default createRootReducer;
