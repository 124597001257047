// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";

import { commonSearch } from "../../actions/actions";

type Props = {
  history: Object,
  track: string => void,
  containerClassName?: string
};

type State = {
  text: string
};

class SearchBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
  }

  submitText = event => {
    event.preventDefault();
    // do nothing if its empty
    const { text } = this.state;
    const trimmedText = text.trim();

    if (trimmedText === "") return;

    // remove duplicated whitespace because its illegal (i.e. double spaces)
    const cleanedText = trimmedText.replace(/\s\s+/g, " ");

    const { history, track } = this.props;

    track(cleanedText);

    // double encode because react router decodes on push
    history.push(
      `/lemma/${encodeURIComponent(encodeURIComponent(cleanedText))}`
    );

    // clear search bar
    this.setState({
      text: ""
    });
  };

  updateText = event =>
    this.setState({
      text: event.target.value.toLowerCase()
    });

  render() {
    const { text } = this.state;

    // get text field props as 'restProps'
    const {
      history,
      staticContext,
      track,
      containerClassName,
      ...restProps
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={this.submitText}
        className={containerClassName}
      >
        <TextField {...restProps} onChange={this.updateText} value={text} />
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  track: lemma => dispatch(commonSearch(lemma))
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(SearchBar)
);
