// @flow
import React from "react";

import Web3Required from "../../containers/common/Web3Required";
import DonateButton from "../../containers/donate/DonateButton";
import ReleaseTimer from "../../containers/release/ReleaseTimer";
import WithdrawButton from "../../containers/withdraw-button/WithdrawButton";

import "./Footer.css";

const Footer = () => (
  <footer id="footer" className="footer">
    <div className="footer-left-align">
      <ReleaseTimer />
    </div>
    <div className="footer-right-align">
      <Web3Required>
        <WithdrawButton />
      </Web3Required>
      <DonateButton />
    </div>
  </footer>
);

export default Footer;
