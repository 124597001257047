// @flow
import { Component } from "react";
import { connect } from "react-redux";

import * as selectors from "../selectors/selectors";
import { web3Initialize } from "../actions/actions";

type Props = {|
  +isAuthenticated: boolean,
  +authenticate: () => void
|};
// Authenticate the user for a valid ETH account
class AuthenticationWeb3 extends Component<Props> {
  // On mount authenticate user
  componentDidMount() {
    const { isAuthenticated, authenticate } = this.props;
    if (!isAuthenticated) {
      authenticate();
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: selectors.isWeb3Authenticated(state)
});

export default connect(
  mapStateToProps,
  {
    authenticate: web3Initialize
  }
)(AuthenticationWeb3);
