// @flow
import type { Saga } from "redux-saga";
import { takeEvery, select, call } from "redux-saga/effects";

import {
  isWeb3Authenticated,
  getWeb3Account,
  getNetwork
} from "../selectors/selectors";

import * as eventFunctions from "../analytics/events";
import mapActionsToEvents from "../analytics/mapActionsToEvents";
import { track } from "../analytics/analytics";

// get web3 props
// is authed
// address
// network
function* getWeb3Properties() {
  const state = yield select();

  const isWeb3Authed = yield call(isWeb3Authenticated, state);

  if (!isWeb3Authed) return { isWeb3Authed };

  // if authed get address and network
  const address = yield call(getWeb3Account, state);
  const network = yield call(getNetwork, state);

  return {
    isWeb3Authed,
    address,
    network
  };
}

function* telemetry({ type, payload }) {
  const eventInformation = mapActionsToEvents[type];

  if (eventInformation) {
    const { event: eventName, data } = eventInformation;

    const eventFunction = eventFunctions[eventName];

    if (!eventFunction) return;

    const { event, properties } = yield call(eventFunction, {
      ...data,
      ...payload
    });

    // get web3 properties
    const web3Props = yield call(getWeb3Properties);

    yield call(track, event, { ...properties, ...web3Props });
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

// watch all actions for payloads with a message
export function* trackActions(): Saga {
  yield takeEvery("*", telemetry);
}
