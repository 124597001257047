// @flow
import type { Saga } from "redux-saga";
import { takeEvery, put, call, select, all, take } from "redux-saga/effects";

import { WEB3_TRANSACTION } from "../actions/action-types";
import { web3Transaction } from "../actions/actions";

import { getWeb3Account } from "../selectors/selectors";

import { getContractDetails } from "../services/contracts/details";
import { sendTransaction } from "../services/contracts/transactions";

import * as userMessages from "../services/web3-method-messages";
import { etherToWei } from "../services/utils";

import {
  successHandlers,
  confirmationHandlers,
  errorHandlers
} from "./transaction-handlers";

/** *************************************************************************** */
/** *************************** Subroutines *********************************** */
/** *************************************************************************** */

function* handleTransactionSuccess({
  payload: { method, params, account, value }
}) {
  // get the method's successd handler
  const handler = successHandlers[method];

  if (!handler) return;

  yield call(handler, { account, params, value });
}

function* handleTransactionConfirmation({ payload: { receipt, method } }) {
  // get the method's confirmation handler
  const handler = confirmationHandlers[method];

  if (!handler) return;

  yield call(handler, receipt);
}

function* handleTransactionFailure({ payload: { method } }) {
  // get the method's error handler
  const handler = errorHandlers[method];

  if (!handler) return;

  yield call(handler);
}

function* handleTransactionRequest({
  payload: { contract, method, value, params }
}) {
  // always make sure we have the contract
  const { abi, address } = yield call(getContractDetails, contract);

  // if we do not have contract data return
  if (!abi || !address) return;

  // get the current account
  const account = yield select(getWeb3Account);

  // if we don't have an account return
  if (!account) return;

  // get the messages for this method
  const messages = userMessages[method] || {};

  // globally define the transaction channel
  let transactionChannel;

  try {
    // only creates the channel on a successful transaction
    transactionChannel = yield call(sendTransaction, {
      abi,
      address,
      method,
      from: account,
      params,
      value
    });

    // first action will be the transaction hash, meaning the user confirmed the transaction on MetaMask!
    yield take(transactionChannel);

    // update the UI while we wait for confirmation
    yield put(
      web3Transaction.success({
        method,
        account,
        params,
        value: etherToWei(parseFloat(value))
      })
    );

    // take an event from the channel (receipt or error)
    // error will throw
    const receipt = yield take(transactionChannel);

    yield put(
      web3Transaction.confirmation({
        receipt,
        method,
        message: messages.success
      })
    );

    // close channel
    transactionChannel.close();
  } catch (error) {
    console.error(error);
    // put failure
    yield put(web3Transaction.failure({ method, message: messages.failure }));
    // if the transactionChannel exists, close it
    if (transactionChannel) {
      transactionChannel.close();
    }
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

export default function* watchTransactions(): Saga {
  yield all([
    takeEvery(WEB3_TRANSACTION.REQUEST, handleTransactionRequest),
    takeEvery(WEB3_TRANSACTION.SUCCESS, handleTransactionSuccess),
    takeEvery(WEB3_TRANSACTION.CONFIRMATION, handleTransactionConfirmation),
    takeEvery(WEB3_TRANSACTION.FAILURE, handleTransactionFailure)
  ]);
}
