export const homeBlocks = ({ name }) => ({
  event: "Home Blocks",
  properties: {
    name
  }
});

export const headerLink = ({ name }) => ({
  event: "Header Link",
  properties: {
    name
  }
});

export const releaseTimer = ({ time }) => ({
  event: "Release Timer",
  properties: { time }
});

export const lemmaPage = ({ lemma, id }) => {
  // if no lemma return lemma id page
  if (!lemma) {
    return {
      event: "Lemma Id Page",
      properties: {
        id
      }
    };
  }

  // return lemma page with the lemma
  return {
    event: "Lemma Page",
    properties: {
      lemma
    }
  };
};

export const releasePage = ({ id = "current" }) => ({
  event: "Release Page",
  properties: {
    release: id
  }
});

export const releaseBlock = ({ lemma, price }) => ({
  event: "Release Block",
  properties: {
    lemma,
    price
  }
});

export const search = ({ lemma }) => ({
  event: "Search",
  properties: {
    lemma
  }
});

export const pageView = ({ page }) => ({
  event: "Page View",
  properties: {
    page
  }
});

export const modal = ({ action, name }) => ({
  event: "Modal",
  properties: {
    action,
    name
  }
});

export const userMessage = ({ kind, text }) => ({
  event: "User Message",
  properties: {
    kind,
    text
  }
});

export const withdrawButton = () => ({
  event: "Withdraw Funds",
  properties: {}
});
