// @flow
import React, { type Node } from "react";

import "./Button.css";

type Props = {
  disabled?: boolean,
  children: Node,
  onClick: any => void
};

const Button = ({ children, onClick, disabled = false }: Props) =>
  disabled ? (
    <button
      type="button"
      className="lexiconomy-button lexiconomy-button-disabled"
    >
      {children}
    </button>
  ) : (
    <button type="button" className="lexiconomy-button" onClick={onClick}>
      {children}
    </button>
  );

export default Button;
