// @flow
import React from "react";
import { connect } from "react-redux";

import * as selectors from "../../selectors/selectors";

import LemmaChartContainer from "./LemmaChartContainer";

type OwnProps = {
  id: string | number,
  lemma: string
};

type StateProps = {|
  +saleAuctionLive: boolean
|};

type Props = OwnProps & StateProps;

// responsible for rendering a chart container if an auction is live
const LemmaCharts = ({ id, lemma, saleAuctionLive }: Props) => {
  if (!saleAuctionLive) return null;

  return <LemmaChartContainer auctionType="sale" id={id} lemma={lemma} />;
};

const mapStateToProps = (state, { id }: OwnProps): StateProps => ({
  saleAuctionLive: selectors.isAuctionLive(state, "sale", id)
});

export default connect(mapStateToProps)(LemmaCharts);
