import { reduce } from "lodash";

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

// create a redux createAction
export const createAction = (type, payload = {}) => ({
  type,
  payload
});

// For each request, create a request, success, failure type
const createRequestTypes = base =>
  reduce(
    [REQUEST, SUCCESS, FAILURE],
    (types, type) => {
      types[type] = `${base}_${type}`;
      return types;
    },
    {}
  );

/** COMMON TYPES * */
export const COMMON_HEADER_CLICK = "COMMON_HEADER_CLICK";

export const COMMON_SEARCH = "COMMON_SEARCH";

export const HOME_BLOCK_CLICK = "HOME_BLOCK_CLICK";

/** WEB3 AUTH ACTION TYPES * */
export const WEB3_INITIALIZE = "WEB3_INITIALIZE";
export const WEB3_AUTHENTICATION = createRequestTypes("WEB3_AUTHENTICATION");

export const WEB3_TRANSACTION = {
  ...createRequestTypes("WEB3_TRANSACTION"),
  CONFIRMATION: "WEB3_TRANSACTION_CONFIRMATION"
};

/** GLOBAL MESSAGE ACTION TYPES * */
export const MESSAGE_OPEN = "MESSAGE_OPEN";
export const MESSAGE_CLOSE = "MESSAGE_CLOSE";

/** GLOBAL MODAL ACTION TYPES * */
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

/** LEMMA ACTION TYPES * */
export const LEMMA_PAGE_LOAD = "LEMMA_PAGE_LOAD";
export const LEMMA_DATA = createRequestTypes("LEMMA_DATA");

export const LEMMA_COMPOSE_MODAL_LOAD = "LEMMA_COMPOSE_MODAL_LOAD";

/** AUCTION ACTION TYPES * */
export const SALE_AUCTION_DATA = createRequestTypes("SALE_ACTION_DATA");

/** RELEASE ACTION TYPES * */
export const RELEASE_PAGE_LOAD = "RELEASE_PAGE_LOAD";
export const RELEASE_PAGE_ID = "RELEASE_PAGE_ID";

export const RELEASE_DATA = createRequestTypes("RELEASE_DATA");
export const RELEASE_CURRENT_DATA = createRequestTypes("RELEASE_CURRENT_DATA");

export const RELEASE_TIMER_LOAD = "RELEASE_TIMER_LOAD";
export const RELEASE_TIMER_SUCCESS = "RELEASE_TIMER_SUCCESS";
export const RELEASE_TIMER_CLICK = "RELEASE_TIMER_CLICK";

export const RELEASE_BLOCK_CLICK = "RELEASE_BLOCK_CLICK";

export const WITHDRAW_BUTTON_LOAD = "WITHDRAW_BUTTON_LOAD";
export const WITHDRAW_BUTTON_CLICK = "WITHDRAW_BUTTON_CLICK";
export const WITHDRAW_FUNDS_SUCCESS = "WITHDRAW_FUNDS_SUCCESS";
